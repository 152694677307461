
// import _, { forEach } from "lodash";
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { List, Enumerable } from 'linq-collections';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { quizPlayerEventBus } from '@/main';
import GlobalHelper from "@/classes/common/globalHelper";
// Components
import CompText from './CompText.vue';
import CompBlock from './CompBlock.vue';
import Linebreak from './CompLinebreak.vue';
import CompImage from './CompImage.vue';
import CompVideo from './CompVideo.vue';
import CompPdf from './CompPdf.vue';
import CompChoice from './CompChoice.vue';
import CompHtml from './CompHtml.vue';
import CompSpeak from './CompSpeak.vue';
// import dchtmlelement from './dchtmlelement.vue';
import CompSelect from './CompSelect.vue';
import CompRight from './CompRight.vue';
import CompWrong from './CompWrong.vue';
import CompSignature from './CompSignature.vue';
import CompMultipleSteps from './CompMultipleSteps.vue';
import CompSurveyRating from './CompSurveyRating.vue';
import CompSurveySlider from './CompSurveySlider.vue';
import CompSurveyComment from "./CompSurveyComment.vue";

// Interfaces
import { ExDataSet, ExViewElement, ExChoiceList, ExChoiceListItem, ExChoice, ExQuestion } from '../../interfaces/ExDataSet';
import { ExerciseVM } from '../../interfaces/ExerciseVM';
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";

const globals = namespace("globals");
const auth = namespace("auth");
const ux = namespace("ux");
const quizplayerstore = namespace("quizplayerstore");

@Component({
  components: {
    CompText,
    CompBlock,
    Linebreak,
    CompImage,
    CompVideo,
    CompPdf,
    CompChoice,
    CompHtml,
    CompSpeak,
    CompSelect,
    CompRight,
    CompWrong,
    CompSignature,
    CompMultipleSteps,
    CompSurveyRating,
    CompSurveySlider,
    CompSurveyComment
  }
})
export default class QuizPlayer extends Vue {
  @globals.Getter selectedExercise!: ExerciseVM;
  @globals.Getter selectedExBundleAssignment!: ExBundleAssignmentVM;
  @quizplayerstore.Getter childQuestions!: ExQuestion[];
  @quizplayerstore.Getter selectedChildQuestion!: ExQuestion;
  @quizplayerstore.Getter selectedChildQuestionId!: number;
  @quizplayerstore.Getter choicesRightAnswered!: boolean;
  @quizplayerstore.Getter allChoicesSelected!: boolean;
  // @quizplayerstore.Getter selectedChoices!: string[];
  @quizplayerstore.Action setQuestion: any;
  @quizplayerstore.Action getSelectListsFromQuestion: any;
  @auth.Getter isGroupAdmin: any;
  // @auth.Getter isParent: any;
  @auth.Getter isLearner: any;
  @ux.State darkTheme: any;
  @ux.State reserveSpaceForDrawer: any;

  finalizeQuestion: boolean = false;
  // disableVerifyButton: boolean = true;
  // selectLists: ExChoiceList[] = [];

  showNextButton: boolean = false;
  showContent: boolean = false;
  showResolution: boolean = false;
  dataSet: ExDataSet | null = null;
  currentQuestion: ExQuestion | null = null;
  questionIndex: number = 0; // Index of current parent question
  parentQuestionCount: number = 0;
  questionCountInclChildQuestions: number = 0;
  progressCounter: number = 0;
  successCount: number = 0;

  $refs!: {
    nextButton: any;
  };

  mounted() {
    if (this.selectedExercise == null) {
      router.push("/");
      return;
    }
    this.changeStyle();
    this.updateQuestions();
  }

  created() {
    // console.log("created");
    quizPlayerEventBus.$off('clicked:Text');
    quizPlayerEventBus.$on('clicked:Text', (isRight) => {
      // console.log("clicked:Text isRight " + isRight);
      this.onAnswered(isRight, false);
    });

    quizPlayerEventBus.$off('changed:score');
    quizPlayerEventBus.$on('changed:score', (params) => {
      // console.log("params", params);
      if (!this.dataSet?.results)
        return;

      let result = new List(this.dataSet.results).single((r) => r.questionId == params.questionId);
      result.score = params.score;
    });

    quizPlayerEventBus.$off('changed:Comment');
    quizPlayerEventBus.$on('changed:Comment', (params) => {
      // console.log("params", params);
      if (!this.dataSet?.results)
        return;

      let result = new List(this.dataSet.results).single((r) => r.questionId == params.questionId);
      result.comment = params.comment;
    });

    quizPlayerEventBus.$off('changed:signature');
    quizPlayerEventBus.$on('changed:signature', (params) => {
      console.log("changed:signature", params);
      if (!this.dataSet?.results)
        return;

      let result = new List(this.dataSet.results).single((r) => r.questionId == params.questionId);
      result.signature = params.signature;
      this.onAnswered(true, false);
    });

    // Disable body scroll on iOS
    this.$nextTick(async () => {
      await this.$globalHelper.delay(10);
      const modal = document.querySelector('.modal');
      disableBodyScroll(modal);
    });
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  get fitView() {
    return this.reserveSpaceForDrawer ? "margin-left: 256px;" : "";
  }

  get exProgress() {
    if (this.questionCountInclChildQuestions == 0)
        return 0;
    // console.log("progress counter: " + this.progressCounter);
    // console.log("question count:  " + this.questionCountInclChildQuestions);
    return this.progressCounter * 100 / this.questionCountInclChildQuestions;
  }

  async updateQuestions() {
    this.showNextButton = false;
    this.showContent = false;
    this.showResolution = false;
    this.dataSet = await rest.url((this.selectedExercise.serviceUri as string)).post(this.selectedExercise);
    //console.log("data set:\r\n" + this.dataSet.currentQuestion[0].viewElements);
    if (this.dataSet?.questions == null)
      return;

    // Select first question
    this.currentQuestion = this.dataSet.questions[0];
    // console.log("Question steps: " + this.questionCount);
    this.parentQuestionCount = new List(this.dataSet.questions).count();
    let parentQuestionsWithoutChildrenCount = new List(this.dataSet.questions).where(q => !q.hasChildQuestions).count();
    let childQuestionCount = new List(this.dataSet.questions).where(q => q.questions != null).selectMany(q => q.questions!).count();
    this.questionCountInclChildQuestions = parentQuestionsWithoutChildrenCount + childQuestionCount;
    // console.log("Question incl. steps: " + this.questionCountInclChildQuestions);
    this.successCount = 0;

    // this.$store.commit("quizplayerstore/setSelectedChildQuestionId", 0);
    // this.$store.commit("quizplayerstore/setChildQuestions", this.currentQuestion.questions);
    this.setQuestion(this.currentQuestion);

    if (this.currentQuestion.hasChildQuestions)
      this.currentQuestion.choices = this.selectedChildQuestion.choices;
    // this.updateSelectLists(this.currentQuestion, this.selectedChildQuestion);

    await this.$globalHelper.delay(500);
    this.showContent = true;
  }

  async onAnswered(isRight: boolean, isMultipleChoice: boolean) {
    // console.log("onAnswered1");
    this.progressCounter++;
    this.$emit("progress:Player", this.exProgress);

    if (!this.dataSet?.results || !this.currentQuestion?.choices)
        return;

    // console.log("onAnswered2");
    if (this.currentQuestion.hasChildQuestions) {
      // console.log("Set selectedChildQuestion: " + isRight)
      this.selectedChildQuestion.isRightAnswered = isRight;
      this.$store.commit("quizplayerstore/setSelectedChildQuestion", this.selectedChildQuestion);
    }

    this.finalizeQuestion = true;
    this.dataSet.results[this.questionIndex].rightAnswered = isRight;

    if (isRight) {
      // console.log("isRight");
      this.successCount++;

      if (this.currentQuestion.hasChildQuestions) {
        // console.log("hasChildQuestions");
        if (this.selectedExercise.alwaysShowResolution && (this.selectedChildQuestion.resolutionViewElements?.length ?? 0 > 0)) {
          this.selectedChildQuestion.showResolution = true;
          this.showNextButton = true;
          this.$globalHelper.animateScrollToBottom("quizPlayerContent", 800);
          // console.log("set focus1");
          await this.$globalHelper.delay(10);
          this.$refs.nextButton.$el.focus();
          return;
        }
        // On last child question show next button, so the pupil could verify there results
        if (this.selectedChildQuestionId + 1 == this.childQuestions.length) {
          this.showNextButton = true;
          this.$globalHelper.animateScrollToBottom("quizPlayerContent", 800);
          await this.$globalHelper.delay(10);
          this.$refs.nextButton.$el.focus();
          return;
        }
      }

      if (this.selectedExercise.alwaysShowResolution && (this.currentQuestion.resolutionViewElements?.length ?? 0 > 0)) {
        this.showContent = false;
        this.showResolution = true;
        this.showNextButton = true;
        this.$globalHelper.animateScrollToBottom("quizPlayerContent", 800);
        await this.$globalHelper.delay(10);
        this.$refs.nextButton.$el.focus();
        return;
      }

      if (isMultipleChoice) {
        // On MC always show next button, so the pupils have a chance to check their answers
        this.showNextButton = true;
        this.$globalHelper.animateScrollToBottom("quizPlayerContent", 800);
        await this.$globalHelper.delay(10);
        this.$refs.nextButton.$el.focus();
        return;
      }

      // Show short animation of choice buttons and go to next exercise
      await this.$globalHelper.delay(500);
      this.onNext();
      return;
    }

    // On wrong answer
    // console.log("wrong answer");
    if (this.selectedExercise.alwaysShowResolution || this.selectedExercise.showResolutionOnWrongAnswer) {
      if (this.currentQuestion.hasChildQuestions && (this.selectedChildQuestion.resolutionViewElements?.length ?? 0) > 0) {
        this.selectedChildQuestion.showResolution = true;
      } else if (this.currentQuestion.resolutionViewElements?.length ?? 0 > 0) {
        this.showContent = false;
        this.showResolution = true;
      }
    }

    // delay show next button
    await this.$globalHelper.delay(1000);

    if (this.selectedExercise.type == "Rule" && !this.showResolution) {
      this.onNext();
      return;
    }

    this.showNextButton = true;
    this.$globalHelper.animateScrollToBottom("quizPlayerContent", 800);
    await this.$globalHelper.delay(10);
    this.$refs.nextButton.$el.focus();
  }

  async onVerify() {
    // console.log("onVerify");
    // this.$emit("progress:Player", this.exProgress);

    if (!this.dataSet?.results || !this.currentQuestion?.choices)
      return;

    // let isRight = !new List(this.selectLists).any(l => l.state == "F");

    //Update result in choiceButton to show success status on finalize
    this.currentQuestion.choices[0].isRight = this.choicesRightAnswered;

    if (this.currentQuestion.hasChildQuestions) {
      this.selectedChildQuestion.isRightAnswered = this.choicesRightAnswered;
      this.$store.commit("quizplayerstore/setSelectedChildQuestion", this.selectedChildQuestion);
    }

    this.finalizeQuestion = true;
    if (this.selectedExercise.alwaysShowResolution || (this.selectedExercise.showResolutionOnWrongAnswer && !this.choicesRightAnswered)) {
      if (this.currentQuestion.hasChildQuestions) {
        if (this.selectedChildQuestion.resolutionViewElements?.length ?? 0 > 0) {
          this.selectedChildQuestion.showResolution = true;
          this.$globalHelper.animateScrollToBottom("quizPlayerContent", 500);
        }
      } else if (this.currentQuestion.resolutionViewElements?.length ?? 0 > 0) {
        this.currentQuestion.showResolution = true;
        this.$globalHelper.animateScrollToBottom("quizPlayerContent", 500);
      }
    }

    this.dataSet.results[this.questionIndex].rightAnswered = this.choicesRightAnswered;

    await this.$globalHelper.delay(500);

    if (this.currentQuestion.hasChildQuestions && (this.selectedChildQuestionId + 1 < this.childQuestions.length)) {
      // In case of child questions immediately show next question because results of this multiple choice question will be still visible. Don't call onAnswered()
      this.progressCounter++; // Count child questions too
      this.$emit("progress:Player", this.exProgress);
      if (this.choicesRightAnswered)
          this.successCount++;
      this.onNext();
      return;
    }

    this.onAnswered(this.choicesRightAnswered, true);
  }

  // TODO: Is this function needed anymore?
  async onNextSurveyQuestion() {
    this.progressCounter++; // Count child questions too
    this.$emit("progress:Player", this.exProgress);

    this.onNext();
  }

  // Next button pressed
  async onNext() {
    // console.log("onNext");

    if (!this.dataSet || !this.dataSet.results || !this.dataSet.questions)
      return;

    this.showNextButton = false;

    if (this.currentQuestion?.hasChildQuestions) {
      // If not finished go to next child question
      if (this.selectedChildQuestionId + 1 < this.childQuestions.length) {
        // this.updateSelectLists(this.currentQuestion, this.selectedChildQuestion);
        // set finalized to false => reenable QuizPlayer UI for next child question
        this.finalizeQuestion = false;
        // console.log("selectNextQuestion delay(50)");
        // await this.$globalHelper.delay(50); // TODO: avoid delay here if possible
        // this.$store.commit("quizplayerstore/setSelectedChildQuestionId", this.selectedChildQuestionId + 1);
        this.$store.commit("quizplayerstore/incrementChildQuestionId");
        this.currentQuestion.choices = this.selectedChildQuestion.choices;
        // console.log("animateScrollToBottom");
        this.$globalHelper.animateScrollToBottom("quizPlayerContent", 800);
        return;
      }
    }

    if (this.selectedExercise.type == "Survey")
      this.updateParentOrChildQuestionResults(this.currentQuestion);
    this.showContent = false;
    this.showResolution = false;

    // Exercise finished
    if (this.questionIndex + 1 >= this.parentQuestionCount) {
      if (this.currentQuestion?.hasChildQuestions) {
        // look if all child questions are right answered
        let succeeded = !new List(this.childQuestions).any(q => !q.isRightAnswered);
        this.dataSet.results[this.questionIndex].rightAnswered = succeeded;
      }
      this.currentQuestion = null; // reset view
      // console.log("dataSet.result: " + this.dataSet.results[this.questionIndex - 1].rightAnswered);
      let resultInPercent = 0;
      // Send results
      // if (this.isLearner) {
      if (this.selectedExBundleAssignment.writeResults) {
        if (this.selectedExercise.type == "Test") {
          let updatedAssignment: ExBundleAssignmentVM = await rest.url("exercises/writeTestResults").post(this.dataSet.results);
          resultInPercent = updatedAssignment.level > this.selectedExBundleAssignment.level ? 100 : 0; // if level has passed set result to 100%
          this.selectedExBundleAssignment.level = updatedAssignment.level;
          this.selectedExBundleAssignment.snooze = updatedAssignment.snooze;
          this.$store.commit('globals/setExBundleAssignment', this.selectedExBundleAssignment);
        } else if (this.selectedExercise.type == "Survey") {
          let updatedAssignment: ExBundleAssignmentVM = await rest.url("exercises/writeSurveyResults").post(this.dataSet.results);
        } else if (this.selectedExercise.type == "Signature") {
          let updatedAssignment: ExBundleAssignmentVM = await rest.url("exercises/writeSignature").post(this.dataSet.results);
        } else {
          await rest.url("exercises/writeExResults").post(this.dataSet.results);
          // Calculate success percentage
          resultInPercent = this.successCount * 100 / this.questionCountInclChildQuestions;
        }
      } else {
        // Admin
        // We do not write results to db but calculate result of current exercise
        if (this.selectedExercise.isTest) {
          resultInPercent = this.successCount >= this.questionCountInclChildQuestions - 1 ? 100 : 0; // if level has passed set result to 100%
        } else {
          resultInPercent = this.successCount * 100 / this.questionCountInclChildQuestions;
        }
      }

      this.$emit("result:Player", resultInPercent);
      return;
    }

    // Select next parent question
    this.questionIndex++;
    this.currentQuestion = this.dataSet.questions[this.questionIndex];
    // this.$store.commit("quizplayerstore/setSelectedChildQuestionId", 0);
    // this.$store.commit("quizplayerstore/setChildQuestions", this.currentQuestion.questions);
    this.setQuestion(this.currentQuestion);

    if (this.currentQuestion.hasChildQuestions) {
      this.currentQuestion.choices = this.selectedChildQuestion.choices;
    }

    // this.updateSelectLists(this.currentQuestion, this.selectedChildQuestion);
    this.finalizeQuestion = false;
    // this.disableVerifyButton = true;
    // this.$emit("progress:Player", this.exProgress);

    await this.$globalHelper.delay(200);
    this.showContent = true;
  }

  // async onTextClicked(isRight: boolean) {
  //   this.onAnswered(isRight, false);
  // }

  // TODO: Check if we could update results already on setChoiceState event in QuizPlayerStore
  updateParentOrChildQuestionResults(question: ExQuestion | null) {
    if (!question)
      return;

    if (question.hasChildQuestions) {
      // question.questions!.forEach(async (cq) => {
      for (const cq of question.questions!) {
        this.updateQuestionResults(cq);
      };
    } else {
      this.updateQuestionResults(question);
    }
  }

  updateQuestionResults(question: ExQuestion) {
    if (!question?.viewElements || !this.dataSet?.results)
      return;

    let choiceLists: ExChoiceList[] = [];
    for (let i = 0, len = question.viewElements.length; i < len; i++) {
      if (question.viewElements[i].type != "CompBlock")
        continue;

      let resultList = this.findSelectLists(question.viewElements[i]);
      if (resultList && resultList.length > 0) {
        for (let i = 0, len = resultList.length; i < len; i++) {
          choiceLists.push(resultList[i]);
        }
      }
    }

    if (choiceLists?.length > 0) {
      let choices = new List(choiceLists).where((l) => l.isMultipleChoice && l.selectionLable == "true" && l.multipleChoiceText != null).select(l => l.multipleChoiceText ?? "");
      let selectedChoices = choices.concat(new List(choiceLists).where((l) => !l.isMultipleChoice && l.selectionLable != null).select(l => l.selectionLable ?? "")).toArray();
      let result = new List(this.dataSet.results).single(r => r.questionId == question.questionId);
      // result.resultType = "Choices";
      result.selectedChoices = selectedChoices;
      // console.log(`choises for question ID ${question.questionId}:`, selectedChoices);
    }
  }

  findSelectLists(element: ExViewElement): ExChoiceList[] {
    let selectLists: ExChoiceList[] = [];

    // Call recursive for all child elements
    if (element.elements && element.elements.length > 0) {
      for (let i = 0, len = element.elements.length; i < len; i++) {
        // console.log(element.elements[i].type);
        // if (element.elements[i].type == "CompMultipleSteps")
        //     continue;

        let resultList = this.findSelectLists(element.elements[i]);
        if (resultList && resultList.length > 0) {
          for (let i = 0, len = resultList.length; i < len; i++) {
            // console.log(resultList[i]);
            selectLists.push(resultList[i]);
          }
        }
      }
    }
    // Check if element itself has a choiceList
    if (element.choiceList) {
      // console.log(element.choiceList);
      selectLists.push(element.choiceList);
    }
    return selectLists;
  }

  changeStyle() {
    // adjust old style
    let themeLink = document.getElementById('exerciseTheme');
    if (themeLink) {
      if (this.darkTheme)
        themeLink.setAttribute("href", "/styles/exercises-dark.css");
      else
        themeLink.setAttribute("href", "/styles/exercises-light.css");

      return;
    }

    // Create new one
    let styles = document.createElement('link');
    styles.type="text/css";
    styles.rel="stylesheet";
    styles.id="exerciseTheme";
    if (this.darkTheme)
      styles.href="/styles/exercises-dark.css";
    else
      styles.href="/styles/exercises-light.css";
    document.head.appendChild(styles);
  }

  get backgroundColor() {
    if (this.$vuetify.theme.dark)
      // return "background-image: linear-gradient(160deg, #6497BC 0%, #00355B 50%); ";
      return "background-image: linear-gradient(160deg, #4089BE 0%, #29597B 50%); ";

    return "background-image: linear-gradient(160deg, #E3F2FD 0%, #B5DBFF 70%); ";
  }

  // onKey() {
  //   console.log("Key pressed");
  // }

  goBack() {
    router.go(-1);
  }
}

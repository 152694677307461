
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
    components: {}
})
export default class ExFinished extends Vue {
    @Prop() showDialog!: boolean;
    @Prop() resultInPercent!: number;
    // @Prop() isTest!: boolean;
    @Prop() type!: string;
    @Prop() level!: number;
    @Prop() maxLevel!: number;

    localShowDialog: boolean = false;
    showStar1: boolean = false;
    showStar2: boolean = false;
    showStar3: boolean = false;
    showStar4: boolean = false;
    showStar5: boolean = false;

    // $refs!: {};

    mounted() {}

    @Watch("showDialog")
    async onShowDialogChanged(val: boolean) {
        // just used as a trigger to toggle dialog visability
        this.localShowDialog = !this.localShowDialog;

        // On dialog open
        if (this.localShowDialog) {
            this.showStar1 = false;
            this.showStar2 = false;
            this.showStar3 = false;
            this.showStar4 = false;
            this.showStar5 = false;
            await this.$globalHelper.delay(300);
            this.showStar1 = true;
            await this.$globalHelper.delay(300);
            this.showStar2 = true;
            await this.$globalHelper.delay(300);
            this.showStar3 = true;
            await this.$globalHelper.delay(300);
            this.showStar4 = true;
            await this.$globalHelper.delay(300);
            this.showStar5 = true;
        }
    }

    get isExercise() {
        return this.type == "Exercise" || this.type =="Rule" || this.type == "ExplanationVideo";
    }

    get isTest() {
        return this.type == "Test";
    }

    get isSurvey() {
        return this.type == "Survey";
    }

    closeDialog() {
        // this.localShowDialog = false;
        this.$emit('update:showDialog', false);
    }
}

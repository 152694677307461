
import { Component, Vue, Prop } from "vue-property-decorator";
import Login from "@/components/Login.vue"; // @ is an alias to /src


@Component({
  components: {
    Login
  }
})
export default class LandingPage extends Vue {
  showAutomation: boolean = false;
  showUseEverywhere: boolean = false;
  showAdaptedLearning: boolean = false;
  showLoginDialog: boolean = false;

  openLoginDialog() {
    // value is use as trigger => just invert it
    this.showLoginDialog = !this.showLoginDialog;
  }
}


import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { namespace } from "vuex-class";
import { vuex } from "@/store";
// import { VRowExpandTransition } from "vuetify/lib"; // ???
// import de from "vuetify/src/locale/de";

// interfaces
import { PortalUserVM, GroupVm } from "@/interfaces/PortalUserVM";
import { GroupExtVm } from "@/interfaces/GroupExtVm";
import { OrganizationVm } from "@/interfaces/OrganizationVm";
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";

// Components
import DynamicGroupOperators from "@/components/Groups/DynamicGroupOperators.vue"

const auth = namespace("auth");
const globals = namespace("globals");

@Component({
  components: {
    DynamicGroupOperators
  }
})
export default class EditGroup extends Vue {
  @Prop() group!: GroupExtVm;
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @Prop() isNewGroup!: boolean;

  @globals.Getter selectedOrg!: OrganizationVm;
  @auth.Getter isAdmin: any;
  @auth.Getter isOrgAdmin: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter canEditDynGroups: any;

  init = true;
  formValid: boolean = true;
  isBusy = false;
  isSearching = false;
  search: string | null = null;
  groupType = "static";

  readAccessUsers: PortalUserVM[] = [] as PortalUserVM[];
  availableUsers: PortalUserVM[] = [] as PortalUserVM[];
  selectedUser: PortalUserVM | null = null;

  $refs!: {
    editClassForm: HTMLFormElement;
  };

  async mounted() {
    // Update user info to see if a new organization was added since login
    // let portalUser: PortalUserVM = await rest.url("Auth/getPortalUser").get();
    // if (portalUser != null)
    //   await vuex.auth.setPortalUser(portalUser);
  }

  @Watch("showDialog")
  async onShowDialogChanged(open: boolean) {
    if (open) {
      // On dialog open
      // Init new group - set selected organization
      this.groupType = this.group.isDynamic ? "dynamic" : "static";
      this.readAccessUsers = [];
      if (this.isNewGroup) {
        this.group.organizationId = this.selectedOrg.id;
        this.group.orgName = this.selectedOrg.name;
      }
      else {
      await this.loadReadAccessUser();
      }

      await this.loadSuggestions();
      this.init = false;

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      this.init = true;
      clearAllBodyScrollLocks();
    }
  }

  async loadReadAccessUser() {
    await rest
      .url("groupAdmin/getReadAccessUsers")
      .query({ groupId: this.group.id })
      .get()
      .then(result => {
        this.readAccessUsers = result;
      })
      .finally(() => this.isSearching = false)
      .catch(err => {
        console.log(err)
      });
  }

  @Watch("search")
  async onSearch(val) {
    if (this.init) return;

    this.debounce();
  }

  debounce = _.debounce(this.loadSuggestions, 700, { leading: true, trailing: true});

  async loadSuggestions() {
    if (this.isSearching)
      return;

    this.isSearching = true;
    this.group.searchValue = this.search;
    this.group.readAccessUsers = this.readAccessUsers;

    rest
      .url("groupAdmin/getGroupLeaderSuggestions")
      .post(this.group)
      .then(result => {
        // console.log(result);
        this.availableUsers = result;
      })
      .finally(() => this.isSearching = false)
      .catch(err => {
        console.log(err)
      });
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  onGroupTypeChanged() {
    this.group.isDynamic = this.groupType == "dynamic";
  }

  // async getGroupLeaderSuggestions() {
  //     this.availableUsers = await rest
  //       .url("groupAdmin/getGroupLeaderSuggestions")
  //       .post(this.group);
  //     this.selectedUser = _.clone(this.defaultUser);
  // }

  get dialogTitle() {
    if (this.isNewGroup) {
      return "Neue Gruppe anlegen";
    }
    return "Gruppe bearbeiten";
  }

  onReadAccessUserSelected() {
    if (this.selectedUser == null || this.readAccessUsers == null)
      return;

    this.readAccessUsers.push(this.selectedUser);

    let index: number = this.availableUsers.indexOf(this.selectedUser);
    this.availableUsers.splice(index, 1);
    this.selectedUser = new PortalUserVmClass();
    this.loadSuggestions();
  }

  onRemoveReadAccessUser(user: PortalUserVM) {
    if (this.readAccessUsers == null)
      return;

    let index: number = this.readAccessUsers.indexOf(user);
    this.readAccessUsers.splice(index, 1);
    // this.availableUsers.push(user);
    this.selectedUser = new PortalUserVmClass();
    this.loadSuggestions();
  }

  closeDialog() {
    this.syncedShowDialog = false;
  }

  async saveGroup() {
    if (!this.$refs.editClassForm.validate()) return;
    this.isBusy = true;
    this.group.readAccessUsers = this.readAccessUsers;
    await rest.url("groupAdmin/saveGroup").post(this.group)
      .then((response) => {
        this.$emit("groupSaved:EditGroup", true);
        this.closeDialog();
      })
      .finally(() => this.isBusy = false)
  }

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length >= 3) || "Name zu kurz."
  ];
}


import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { PortalUserVM, SelectListItem } from "@/interfaces/PortalUserVM";
import UserQrCodeCard from "@/components/Groups/UserQrCodeCard.vue";
import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode
import { GroupVm } from "@/interfaces/GroupVm";
import { ParamMemberSuggestionVm } from "@/interfaces/ParamMemberSuggestionVm";
import { GroupExtVm } from "@/interfaces/GroupExtVm";


const globals = namespace("globals");

@Component({
  components: {
    UserQrCodeCard,
    qrcode: VueQrcode
  }
})
export default class PrintUserQrCodes extends Vue {
  @Prop() visible!: boolean;

  @globals.Getter selectedGroup!: GroupVm;

  localShowView: boolean = false;
  // hideFromPrint = false;
  users: PortalUserVM[] = [];

  async mounted() {
  }

  @Watch("visible")
  async onVisibilityChanged(val: boolean) {
    // just used as a trigger to toggle dialog visibility
    this.localShowView = !this.localShowView;

    // On dialog open
    if (this.localShowView) {
        this.updateUserlList();
    }
  }

  async updateUserlList() {
    if (this.selectedGroup == null)
      return;

    const group: GroupExtVm = await rest.url("groupAdmin/getGroupExt")
      .query({ groupId: this.selectedGroup.id, includePictures: false })
      .get();

    this.users = group.users ?? [];
  }

  async onPrintQrCodes() {
    // await this.$globalHelper.delay(100);
    window.print();
    // await this.$globalHelper.delay(100);
    // this.hideFromPrint = false;
  }

  onClose() {
    this.$store.commit('globals/setViewToPrintMode', false);
    this.localShowView = false;
  }
}


import _, { replace } from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";

const globals = namespace("globals");

@Component({
    components: {}
})
export default class CompSpeak extends Vue {
  @Prop() text!: string;
  @Prop() textArray!: string[];
  @Prop() alignment!: string;
  @Prop() size!: string; // 24, evtl.: x-small, small, medium, large, x-large
  @Prop() color!: string;
  @Prop() weight!: string; // lighter, normal, bold, bolder, 100,200,300,400,500,600,700,800,900
  @Prop() fontStyle!: string; // normal, italic, oblique
  @Prop() finalized!: boolean;
  @Prop() compSettings!: string;

  canSpeak = false;
  speaking = false;
  paused = false;
  synth = speechSynthesis;
  voices: SpeechSynthesisVoice[] | undefined = [];
  voiceList: any[] | null = null;
  selectedVoice: any | null = null; //= { name: "undefined", lang: "de-DE", voiceURI: "undefined", localService: false, default: false };
  wordStart = 0;
  wordEnd = 0;
  settings: any = null;

  async mounted() {
    if (this.compSettings)
      this.settings = JSON.parse(this.compSettings);

    // https://stackoverflow.com/questions/33889107/speech-synthesis-in-chrome-for-android-not-loading-voices
    this.canSpeak = "speechSynthesis" in window;
    if (this.canSpeak) {
      window.speechSynthesis.onvoiceschanged = () => this.selectVoice();
      await this.$globalHelper.delay(50);
      this.selectVoice();
    }
  }

  getGermanVoices() {
    this.voices = this.synth.getVoices().filter(v => {
      return v.lang.replace('_', '-').includes("de-DE")
    });
    this.voiceList = new List(this.voices).select(v => <any>{
      name: v.name, uri: v.voiceURI, lang: v.lang.replace("_", "-"), default: v.default, local: v.localService
    }).toArray();
  }

  selectVoice() {
    this.getGermanVoices();
    if (!this.voiceList || this.voiceList.length == 0) {
      this.selectedVoice = null;
      this.canSpeak = false;
      return;
    }

    // console.log("Voices: ", this.voiceList);

    this.selectedVoice = new List(this.voiceList).firstOrDefault(v => v.default && v.local && (v.name as string).toLowerCase().includes("microsoft"));
    if (!this.selectedVoice)
      this.selectedVoice = new List(this.voiceList).firstOrDefault(v => v.local && (v.name as string).toLowerCase().includes("microsoft"));
    if (!this.selectedVoice)
      this.selectedVoice = new List(this.voiceList).firstOrDefault(v => (v.name as string).toLowerCase().includes("microsoft"));
    if (!this.selectedVoice)
      this.selectedVoice = new List(this.voiceList).firstOrDefault(v => v.local);
    if (!this.selectedVoice)
      this.selectedVoice = new List(this.voiceList).firstOrDefault() ?? null;

    if (!this.selectedVoice) {
      this.canSpeak = false;
      return;
    }

    console.log("Selected voice: " + this.selectedVoice!.name);
    this.canSpeak = true;
  }

  onChanged() {
    console.log("Selected voice: " + this.selectedVoice!.name);
  }

  async onSpeak() {
    if (!this.canSpeak)
      return;

    // Check if speech API is currently used by another instance of COmpSpeak
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
      this.wordStart = 0;
      this.wordEnd = 0;
      this.speaking = false;
      this.paused = false;
      await this.$globalHelper.delay(250);
    }

    let utterance = new SpeechSynthesisUtterance();
    utterance.text = this.text;
    utterance.voice = this.voices!.find(v => v.voiceURI == this.selectedVoice.uri) ?? null;
    let lang = this.selectedVoice!.lang;
    utterance.lang = lang; // Needed for Android to prevent unspecified behavior
    utterance.pitch = this.pitch;
    utterance.rate = this.rate;
    //utterance.volume = 1;
    // utterance.addEventListener('end', handleEnd);
    utterance.onend = () => this.endOfPlaying();
    utterance.onboundary = (e) => this.onBoundry(e);
    this.synth.speak(utterance);
    this.speaking = true;
  }

  onBoundry(e: SpeechSynthesisEvent) {
    // console.log(e.name);
    if (e.name != "word")
      return;
    this.wordStart = e.charIndex;

    let wordLength = e.charLength;
    // iOS workaround
    if (wordLength == undefined) {
      let end = this.text.indexOf(" ", this.wordStart);
      if (end > 0)
        this.wordEnd = end;
      else
        this.wordEnd = this.text.length;
      return;
    }

    this.wordEnd = this.wordStart + wordLength;
  }

  get displayText() {
    if (this.wordStart == this.wordEnd)
      return this.text;

    let word = this.text.substring(this.wordStart, this.wordEnd);
    let markedText = this.text.substring(0, this.wordStart) + '<mark style="background-color: #63a2cfD0;">' + word + '</mark>' + this.text.substring(this.wordEnd);

    return markedText;
  }

  get hideText() {
    if (this.settings?.hideText)
      return this.settings.hideText;

    return false;
  }

  get dense() {
    if (this.settings?.dense)
      return this.settings.dense;

    return false;
  }

  get pitch() {
    if (this.settings?.pitch)
      return this.settings.pitch;

    return 1.2;
  }

  get rate() {
    if (this.settings?.rate)
      return this.settings.rate;

    return 0.8;
  }

  endOfPlaying() {
    this.synth.cancel();
    this.wordStart = 0;
    this.wordEnd = 0;
    this.speaking = false;
    this.paused = false;
  }

  onPauseSpeaking() {
    this.synth.pause();
    this.paused = true;
  }

  onResumeSpeaking() {
    this.synth.resume();
    this.paused = false;
  }

  onStopSpeaking() {
    this.synth.cancel();
    this.wordStart = 0;
    this.wordEnd = 0;
    this.speaking = false;
    this.paused = false;
  }

  get fontSize() {
    if (this.size == null)
        return "18px";
    if (isNaN(Number(this.size))) // Number(this.size) != NaN)
        return this.size;
    return this.size + "px";
  }

  get lineHeight() {
    let fs = this.fontSize.replace("px", "");
    if (isNaN(Number(fs)))
        return this.fontSize;

    return Number(fs) + 2 + "px";
  }

  get hyperText() {
    if (this.text != null)
        return this.text;
    if (this.textArray != null)
        return this.textArray.join('');

    return null;
  }

  // delay(ms: number) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }
}


import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { vuex } from "@/store";
import rest from "@/rest";
import { List, Enumerable } from "linq-collections";
import {
  GridOptions,
  IDatasource,
  IGetRowsParams,
  Grid,
  ColDef,
  GridApi,
  IHeaderGroupParams,
  ValueGetterParams,
  ValueFormatterParams,
  CellValueChangedEvent,
  RowNode, CellDoubleClickedEvent, CellClickedEvent, Column
} from "ag-grid-community";
import 'ag-grid-community/styles/ag-grid.css';
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";

// Components
import AvatarLargeCellRenderer from "@/components/CommonCellRenderers/AvatarLargeCellRenderer.vue";
import ExOverviewContentCellRenderer from "@/components/GroupExerciseOverview/ExOverviewContentCellRenderer.vue";
import ExOverviewStatusCellRenderer from "@/components/GroupExerciseOverview/ExOverviewStatusCellRenderer.vue";

// Interfaces
import { UpdateUserResultsTableVm } from "@/interfaces/UpdateUserResultsTableVm";
import { UserResultsFilterSelectionsVm } from "@/interfaces/UserResultsFilterSelectionsVm";
import { ExBundleAssignmentVM, UserAssignmentsTableDataVm } from "@/interfaces/UserAssignmentsTableDataVm";
import router from "@/router";

const auth = namespace("auth");

@Component({
  components: {
    AgGridVue,
  }
})
export default class ExOverviewTable extends Vue {
  @PropSync("searchFilter") syncedSearchFilter!: UserResultsFilterSelectionsVm;
  @PropSync("rowCount") syncedRowCount!: number;
  @PropSync("totalCount") syncedTotalCount!: number;

  // $refs!: {
  //   fcEntryGrid: HTMLElement;
  // };

  @auth.Getter isAdmin: any;
  @auth.Getter isOrgAdmin: any;

  updateTableVm: UpdateUserResultsTableVm | null = null;
  agGridOptions = this.getGridOptions();
  agGridColumnDefs: ColDef[] = [];
  agGridFrameworkCmpts = {
    AvatarLargeCellRenderer: AvatarLargeCellRenderer,
    ExOverviewStatusCellRenderer: ExOverviewStatusCellRenderer,
    ExOverviewContentCellRenderer: ExOverviewContentCellRenderer
  };
  agGridApi = <GridApi>{};
  selectedRowData: ExBundleAssignmentVM | null = null;
  selectedRowNode: RowNode | null = null;
  selectedRowColumn: Column | null = null;
  selectedColName: string | undefined = "";
  selectedColHeader: string | undefined = "";

  mounted() {
    // this.refresh();
  }

  getGridOptions() {
    return <GridOptions>{
      context: this,
      // datasource: ,
      // data model options
      rowModelType: "infinite",
      rowBuffer: 0,
      cacheBlockSize: 100,
      cacheOverflowSize: 5,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 42,
      maxBlocksInCache: 200,
      // ux
      rowSelection: "multiple",
      sortingOrder: ["asc", "desc"],
      headerHeight: 0,
      rowHeight: 70, // 45,
      suppressContextMenu: true,
      preventDefaultOnContextMenu: true,
      stopEditingWhenCellsLoseFocus: true,
      // enableCellTextSelection: true, // Not working with cell renderers
      // components: {
      //   AvatarLargeCellRenderer: AvatarLargeCellRenderer,
      // },
      // cbs
      onGridReady: async params => {
        // await this.$globalHelper.delay(10);
        this.agGridApi = params.api;
        params.api.onSortChanged = () => null;
        window.addEventListener('resize', function() {
          setTimeout(function() {
            params.api.sizeColumnsToFit();
          })
        })
      },
      // onFirstDataRedndered: event => {
      //   (event as FirstDataRenderedEvent).api.sizeColumnsToFit();
      // }
    };
  }

  getColumnDefs() {
    let colDef = new List();

    colDef.pushRange([
      {
        headerName: "",
        cellRenderer: "AvatarLargeCellRenderer",
        // columnGroupShow: "open",
        // headerClass: "agTextBold",
        field: "user.avatar",
        width: 60,
        suppressSizeToFit: true,
        resizable: false
      },
      {
        headerName: "Name",
        // columnGroupShow: "open",
        cellRenderer: "ExOverviewContentCellRenderer",
        // headerClass: "agTextBold",
        field: "user.fullName",
        // width: 90,
        suppressSizeToFit: false,
        sortable: true,
        resizable: false
      },
      {
        headerName: "Status",
        cellRenderer: "ExOverviewStatusCellRenderer",
        // columnGroupShow: "open",
        // headerClass: "agTextBold",
        field: "",
        width: 80,
        suppressSizeToFit: true,
        resizable: false
      },
      // {
      //   headerName: "Anwenderdaten",
      //   headerClass: "agTextBold",
      //   // headerGroupComponent: "FcHeaderGroupComponent",
      //   children: [
      //     {
      //       headerName: "Name",
      //       // columnGroupShow: "open",
      //       headerClass: "agTextBold",
      //       field: "user.fullName",
      //       // width: 90,
      //       sortable: true,
      //       resizable: false
      //     },
      //     {
      //       headerName: "Vorname",
      //       columnGroupShow: "open",
      //       headerClass: "agTextBold",
      //       field: "user.firstName",
      //       singleClickEdit: false,
      //       editable: false,
      //       sortable: true,
      //       resizable: true,
      //     },
      //     {
      //       headerName: "Nachname",
      //       columnGroupShow: "open",
      //       headerClass: "agTextBold",
      //       field: "user.lastName",
      //       singleClickEdit: true,
      //       editable: true,
      //       sortable: true,
      //       sort: "asc",
      //       resizable: true,
      //     },
      //  ]
      // },
    ]);

    return colDef.toArray() as any[];
  }

  dateFormatter(value: Date) {
    // let user = params.data as PortalUserVM;
    // if (!user || !user.gdprApprovalDate) return "-";
    // console.log("date", value);
    if (!value) return "-";

    let dateAsString = moment(value).format("DD.MM.yyyy");
    return dateAsString;
  }

  async loadData(): Promise<void> {
    // console.log("loadData");
    if (!this.agGridApi)
      return;
    this.agGridApi.ensureIndexVisible(0);
    this.agGridApi.setDatasource(new UserResultsAgGridDataSource());
  }

  async updateTable(): Promise<void> {
    // console.log("Update data");

    // Workaround: On changing CollumnDefs some columns are mistakenly added to the end
    // this.agGridApi.setDatasource(new NoDataDatasource());
    this.agGridColumnDefs = <any>[];
    await this.$globalHelper.delay(50);
    // -------------------

    this.agGridColumnDefs = this.getColumnDefs();
    await this.$globalHelper.delay(50);
    this.agGridApi.ensureIndexVisible(0);
    this.agGridApi.setDatasource(new UserResultsAgGridDataSource());
  }

  refresh(): void {
    this.agGridApi.refreshCells();
  }

  resetColumns(): void {
    this.agGridOptions.columnApi!.resetColumnState();
  }

  adjustColWidth(): void {
    this.agGridApi.sizeColumnsToFit();
  }

  // Not working for rowModelType: "infinite"
  // async loadData() {
  //   // console.log("Load data");
  //   let result = await rest().post("TargetingApi/LoadAmTableData", this.filterSelections);
  //   this.tableData = result.data;
  // }

  // Not working for rowModelType: "infinite"
  // async clear(): Promise<void> {
  // //   this.tableData = [];
  // }

  // async exportUserExcel() {
  //   let updateTableVm = <UpdateUserTableVm>{
  //     getRows: {
  //       startRow: 0, // will be ignored
  //       endRow: 0, // will be ignored
  //       columnName: "lastName",
  //       ascending: true
  //     },
  //     searchFilter: this.syncedSearchFilter,
  //   };

  //   await rest.url("orgAdmin/createUserExcel")
  //     .post(updateTableVm)
  //     .then((fileName) => {
  //       if(fileName)
  //         this.$globalHelper.download(`api/resource/Temp/${fileName}`, fileName);
  //     })
  // }

  // isCellEditable(columnName: string): boolean {
  //   // console.log("isResponsibleCellEditable: " + columnName);

  //   if (columnName == "FirstName" ||
  //     columnName == "LastName"

  //   )
  //     return true;

  //   return false;
  // }

  // isCellInlineEditable(columnName: string): boolean {
  //   // console.log("isCellInlineEditable: " + columnName);
  //   if (
  //     columnName == "FirstName" ||
  //   )
  //     return true;
  //   return false;
  // }

  // getCurrentValue(rowName: string) {
  //   // console.log("getCurrentValue: ", data);
  // }

  // setCurrentValue(user: PortalUserVM, newValueString: string) {
  //   // console.log(newValueString);
  //   return true;
  // }

  // ag-grid value formatter
  // https://www.ag-grid.com/javascript-grid-value-formatters/
  // formatCellValue(value: number, rowName: string) {
  //   let userLang = navigator.language;
  //   // console.log("The language is: " + userLang);
  //   // console.log(rowName);

  //   if (rowName == "User")
  //     return this.toPercent(value);
  //   return value.toLocaleString(userLang, { maximumFractionDigits: 0 });
  // }

  toPercent(value: number) {
    return (value * 100).toFixed(1) + "%";
  }

  toCurrency(value: number): string {
    let userLang = navigator.language;
    return new Intl.NumberFormat(userLang, { style: 'currency', currency: 'EUR' }).format(value);
  }

  /**
   * Events
   */

  onCellValueChanged(params: CellValueChangedEvent) {
    // console.log("onCellValueChanged");
    // console.log(params);

    // this.updateFcGmInPercent();
    // if (params.colDef.field == "Q1.CurrentValue") {
    //   params.data.Q1.ForecastDeviation = params.data.Q1.LastValue - params.data.Q1.CurrentValue;
    //   console.log(params.data.Q1.ForecastDeviation);
    // }
  }

  onRightClick() {
  }

  onCellClick(e: CellClickedEvent) {
    if (!e.data || !e.colDef)
      return;

    // console.log("onCellClick");
    // console.log(e.column);
    // console.log(e.colDef);
    // console.log(e);

    this.selectedRowData = e.data as ExBundleAssignmentVM;
    // console.log(this.selectedRowData);
    this.showExerciseBundle(this.selectedRowData);

    // this.selectedRowNode = e.node;
    // this.selectedRowColumn = e.column;
    // this.selectedColName = e.colDef.field?.split(".")[0] ?? "";
    // this.selectedColHeader = e.colDef.headerName;
    return;
  }

  async showExerciseBundle(bundleAssignmentVm: ExBundleAssignmentVM) {
    vuex.globals.setExBundleAssignment(bundleAssignmentVm);
    router.push("/exerciseBundle");
  }


  async onUpdateSelectedCell(newValue: number) {
    console.log("Update selected cell: " + newValue);
    if (!this.selectedRowNode || !this.selectedRowColumn)
      return;

    this.selectedRowNode.setDataValue(this.selectedRowColumn, newValue);
    // if (this.selectedRowData?.RowType == ForecastingValueType.Rev || this.selectedRowData?.RowType == ForecastingValueType.GM) {
    //   await this.loadData();
    // }
  }

  /**
   * Getters
   */

  /**
   * Helper
   */
}

export class UserResultsAgGridDataSource implements IDatasource {
  getRows = async (params: IGetRowsParams) => {
    let searchFilter: UserResultsFilterSelectionsVm = params.context.searchFilter;
    // sort model
    let sortColumnName: string | null = null;
    let sortAsc = false;
    // check sort
    let sortAgGrid = params.sortModel[0] ?? null;
    if (sortAgGrid) {
      // console.log(sortAgGrid.colId);
      sortColumnName = sortAgGrid.colId.split(".")[0];
      if (sortAgGrid.sort == "desc") sortAsc = false;
      if (sortAgGrid.sort == "asc") sortAsc = true;
    }

    params.context.updateTableVm = <UpdateUserResultsTableVm>{
      getRows: {
        startRow: params.startRow,
        endRow: params.endRow,
        columnName: sortColumnName,
        ascending: sortAsc
      },
      searchFilter: searchFilter
    };

    await rest
      .url("groupAdmin/loadPartialUserResultsForBundle")
      .post(params.context.updateTableVm)
      .then(result => {
        // console.log(result)
        let tableData = result as UserAssignmentsTableDataVm;
        params.context.syncedRowCount = tableData.hits;
        params.context.syncedTotalCount = tableData.total;
        params.successCallback(tableData.assignments as any[], tableData.hits);
        params.context.agGridApi.sizeColumnsToFit();
      });
  };
}

export class NoDataDatasource implements IDatasource {
  getRows = (params: IGetRowsParams) => {
    params.successCallback([]);
  };
}

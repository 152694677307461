
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { watch } from "vue";
import router from "@/router";
import { vuex } from "@/store";
import rest from "@/rest";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
// components
import { QrcodeStream } from "vue-qrcode-reader"; // https://github.com/gruhn/vue-qrcode-reader
// interfaces
import { UserCredentials } from "@/interfaces/UserCredentials";
import { StringWrapper } from "@/interfaces/StringWrapper";

const auth = namespace("auth");

@Component({
  components: {
    QrcodeStream
  }
})
export default class Login extends Vue {
  // @Prop({ default: false }) showDialog: boolean;
  @Prop() showDialog!: boolean;
  @auth.Action PasswordLogin: any;
  @auth.Action RegisterUser: any;

  localShowDialog: boolean = false;
  uiState: number = 1;
  userState: string = "";
  showSignInInfo = false;
  showQrScanner = false;
  qrScannerReady = false;
  qrError: string = "";
  errors: any

  userCredentials = {} as UserCredentials;
  password1: string = "";
  password2: string = "";
  showPassword: boolean = false;
  loginValid = true;
  activationValid = true;

  showPwdResetDlg = false;
  showPwdResetReqSuccess = false;
  applicantEmail = "";
  pwdResetReqValid = false;
  gdprAccepted = false;

  $refs!: {
    loginForm: HTMLFormElement;
    // userNameForm: HTMLFormElement;
    activationForm: HTMLFormElement;
    pwdResetReqForm: HTMLFormElement;

    // Needed to focus text box
    usernameTextField: HTMLElement;
    passwordTextField: HTMLElement;
    activationTextField: HTMLElement;
    qrcodediv: HTMLElement;
    // cancelButton: any;
  };

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  // mounted() {
  //   this.$validator.extend('falsy', (value) => ! value);
  //   this.$validator.attach({ name: 'falseField', rules: 'falsy' });
  // }

  @Watch("showDialog")
  async onShowDialogChanged(val: boolean) {
    // just used as a trigger to toggle dialog visability
    this.localShowDialog = !this.localShowDialog;

    if (this.localShowDialog) {
      // On dialog open
      //   const modal = document.querySelector('.modal');
      //   disableBodyScroll(modal);

      this.uiState = 1;
      this.showPassword = false;
      // if (this.$refs.userNameForm != undefined) this.$refs.userNameForm.reset();
      if (this.$refs.loginForm != undefined)
        this.$refs.loginForm.reset();
      if (this.$refs.activationForm != undefined)
        this.$refs.activationForm.reset();
      this.showQrScanner = false;
      this.qrError = "";
      await this.$globalHelper.delay(300);
      this.$refs.usernameTextField.focus();

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closed
      clearAllBodyScrollLocks();
    }
  }

  async onInit(promise) {
    try {
      this.qrScannerReady = false;
      await promise;
      // await this.$globalHelper.delay(100);
      this.qrScannerReady = true;
      // await this.$globalHelper.delay(100);
      //this.$refs.cancelButton.$el.blur();
       this.$refs.qrcodediv.focus();
    } catch (qrError: any) {
      if (qrError.name === "NotAllowedError") {
        this.qrError = "Sie müssen den Zugriff auf die Kamera freigeben!"; // "ERROR: you need to grant camera access permission"
      } else if (qrError.name === "NotFoundError") {
        this.qrError = "Dieses Gerät besitzt keine Kamera!"; // "ERROR: no camera on this device"
      } else if (qrError.name === "NotSupportedError") {
        this.qrError = "Der Zugriff auf diese Webanwendung muss über eine sichere Verbindung erfolgen (HTTPS, localhost)."; // "ERROR: secure context required (HTTPS, localhost)"
      } else if (qrError.name === "NotReadableError") {
        this.qrError = "Die Kamera wird bereits von einer anderen Anwendung benutzt."; // "ERROR: is the camera already in use?"
      } else if (qrError.name === "OverconstrainedError") {
        this.qrError = "Die verfügbaren Kameras sind nicht geeignet."; // "ERROR: installed cameras are not suitable"
      } else if (qrError.name === "StreamApiNotSupportedError") {
        this.qrError = "Die Stream API wird von diesem Browser nicht unterstützt."; // "ERROR: Stream API is not supported in this browser"
      }
    }
  }

  async onShowQrScanner() {
    // await this.$globalHelper.delay(10);
    // this.$refs.usernameTextField.blur();
    this.showQrScanner = true;
    // await this.$globalHelper.delay(10);
    // this.$refs.cancelButton.$el.focus();
  }

  onDecode(decodedString) {
    // console.log(decodedString);
    if (decodedString.substr(0, 3) == "dc-") {
      this.userCredentials.username = decodedString.substr(3);
      this.onNext();
    } else {
      this.userCredentials.username = "Ungültiger QR Code";
    }
  }

  async onCancel() {
    // if (this.$refs.userNameForm != undefined) this.$refs.userNameForm.reset();
    if (this.showQrScanner) {
      this.showQrScanner = false;
      await this.$globalHelper.delay(20);
      this.$refs.usernameTextField.focus();
      return;
    }

    if (this.$refs.loginForm != undefined) this.$refs.loginForm.reset();
    if (this.$refs.activationForm != undefined)
      this.$refs.activationForm.reset();

    clearAllBodyScrollLocks();
    this.localShowDialog = false;
  }

  async doLogin() {
    // if (!(await this.$validator.validate()))
    //     return;
    if (!this.$refs.loginForm.validate()) return;

    await this.PasswordLogin(this.userCredentials);
    if (this.$store.state.auth.isLoggedIn) {
      // this.$store.commit("ux/SB_SUCCESS", {
      //   message: "Anmeldung erfolgreich :)",
      //   timeout: 3000
      // });
      vuex.ux.SB_SUCCESS({
        message: "Anmeldung erfolgreich :)",
        timeout: 3000
      });

      this.onCancel();
      router.push("/home");
    }
  }

  async onNext() {
    if (!this.userCredentials?.username){
      this.$refs.usernameTextField.blur();
      return;
    }

    switch (this.uiState) {
      case 1:
        this.$refs.usernameTextField.blur();
        this.userCredentials.username = this.userCredentials.username?.trim();
        if (this.userCredentials.username.length < 3) {
          return;
        }
        // eslint-disable-next-line no-case-declarations
        let stringWrapper: StringWrapper = {
          value: this.userCredentials.username
        };
        this.userState = await rest
          .url("auth/getUserState")
          .post(stringWrapper);

        if (this.userState == "u") {
          this.uiState = 2;
          // this.$nextTick(() => this.$refs.passwordTextField.focus());
          await this.$globalHelper.delay(300);
          this.$refs.passwordTextField.focus();
        }
        if (this.userState == "a") {
          this.uiState = 3;
          // this.$nextTick(() => this.$refs.activationTextField.focus());
          await this.$globalHelper.delay(300);
          this.$refs.activationTextField.focus();
        }
        if (this.userState == "p") this.doPupilLogin();
        break;
      case 2:
        this.doLogin();
        break;
      case 3:
        this.doRegister();
        break;

      default:
        break;
    }
  }

  onBack() {
    if (this.uiState > 1) {
      this.uiState--;
    }
  }

  async doPupilLogin() {
    this.userCredentials.password = "$Pupil#$";
    await this.PasswordLogin(this.userCredentials);
    if (this.$store.state.auth.isLoggedIn) {
      // this.$store.commit("ux/SB_SUCCESS", {
      //   message: "Anmeldung erfolgreich :)",
      //   timeout: 3000
      // });
      vuex.ux.SB_SUCCESS({
        message: "Anmeldung erfolgreich :)",
        timeout: 3000
      });
      this.onCancel();
      router.push("/home");
    }
  }

  async doRegister() {
    // if (!(await this.$validator.validate())) return;
    if (!this.$refs.activationForm.validate()) return;
    if (this.password1 != this.password2)
      return;

    this.userCredentials.password = this.password1;

    await this.RegisterUser(this.userCredentials);
    if (this.$store.state.auth.isLoggedIn) {
      vuex.ux.SB_SUCCESS({
        message: "Registrierung erfolgreich :)",
        timeout: 3000
      });
      this.onCancel();
      router.push("/home");
    }
  }

  @Watch("showPwdResetDlg")
  onShowPwdResetDlg(show: boolean) {
    if (show) {
    this.applicantEmail = "";
    this.pwdResetReqValid = false;
    }
  }

  async sendPasswordResetReq() {
    if (!this.$refs.pwdResetReqForm.validate()) return;

    let stringWrapper: StringWrapper = {
      value: this.applicantEmail
    };
    await rest
      .url("auth/sendPasswordResetReq")
      .post(stringWrapper)
      .then((response) => {
        vuex.ux.SB_SUCCESS({
          message: "E-Mail wurde verschickt. Bitte überprüfen Sie auch Ihren Spam-Ordner, falls Sie die E-Mail nicht in Ihrem Posteingang sehen.",
          timeout: 30000
        });
      })
      .finally(() => {
        this.gdprAccepted = false;
        this.showPwdResetDlg = false;
      });
  }

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Eingabe erforderlich",
    (v: string) => (v && v.length >= 3) || "Benutzername oder E-Mail Adresse zu kurz."
  ];

  pwdRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz."
  ];

  activationKeyRules = [
    (v: string) => {
      if (!!v as boolean)
        return true;

      return "Aktivierungsschlüssel erforderlich";
    },
    (v: string) => {
      if (v && v.length > 7)
        return true;

      return "Aktivierungsschlüssel ist zu kurz.";
    }
  ];

  // confirmPasswordRules = [
  //   (v: string) => !!v || "Bestätigung erforderlich",
  //   (v: string) => {

  //     if (this.password1 === this.password2) {
  //       console.log("pwds gleich", this.password1, this.password2);
  //       return true;
  //     }

  //     console.log("pwds ungleich");
  //     return "Passwörter sind unterschiedlich"
  //   }
  // ];

  get passwordConfirmed() {
    if (!this.password1)
      return "";
    if (!this.password2)
      return "Passwort erforderlich";
    if (this.password1 == this.password2)
      return "";
    return "Passwörter sind unterschiedlich";
  }

  newPasswordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => {
      if (!v)
        return "Passwort erforderlich";

      let matches = 0;

      if (v.length < 8) return "Passwort muss mindestens 8 Zeichen lang sein";
      if (v.length > 32) return "Passwort ist zu lang";

      let hasDigit = /.*\d.*/;
      let hasLowerCase = /.*[a-z].*/;
      let hasUpperCase = /.*[A-Z].*/;
      let hasSpecialChar = /.*[*.!@#$%^&§(){}\[\]\\:;<>,.?/~_+\-=|'].*/;

      if( hasDigit.test(v) )
        matches ++;
      if( hasLowerCase.test(v) )
        matches ++;
      if( hasUpperCase.test(v) )
        matches ++;
      if( hasSpecialChar.test(v) )
        matches ++;

      if (matches >= 3)
        return true;

      if (matches == 1)
        return `Es ist nur ${matches} von mindestens 3 Kriterien erfüllt. [Kleinbuchstaben, Großbuchstaben, Zahl, Sonderzeichen]`;
      return `Es sind nur ${matches} von mindestens 3 Kriterien erfüllt. [Kleinbuchstaben, Großbuchstaben, Zahl, Sonderzeichen]`;
    }
  ];

}


import _ from "lodash";
import rest from "@/rest";
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Action, namespace } from "vuex-class";
import { PortalUserVM } from "@/interfaces/PortalUserVM";

import de from 'vuetify/src/locale/de';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { ParamDictionary } from "@/interfaces/ParamDictionary";

const globals = namespace("globals");

@Component({
  components: {
  }
})
export default class SendMessage extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @Prop() user!: PortalUserVM;

  showConfInfo = false;
  formValid: boolean = false;
  busy = false;
  messageTitle: string = "";
  messageText: string = "";
  confidentialText: string = "";
  sendMsgParam: ParamDictionary = {dictionary: {}};

  $refs!: {
    dlgForm: HTMLFormElement;
    // firstTextField: HTMLElement;
  };

  mounted() {
  }

  @Watch("syncedShowDialog")
  async onShowDialogChanged(val: boolean) {
    if (this.syncedShowDialog) {
      // On dialog open
      this.messageTitle = "";
      this.messageText = "";
      this.confidentialText = "";
      // await this.$globalHelper.delay(50);
      // this.$refs.firstTextField.focus();
      this.formValid = false;

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  onSubmit() {
    console.log("onSubmit");
    if (!this.formValid)
      return;

    this.onSendMessage();
  }

  async onSendMessage() {
    if (this.user == null)
      return;
    if (!this.$refs.dlgForm.validate())
      return;
    if (this.user == null || !this.formValid)
      return;
    if (this.sendMsgParam == null || this.sendMsgParam.dictionary == null)
      return;

    this.busy = true;
    this.sendMsgParam.dictionary["RecipientId"] = this.user.id!;
    this.sendMsgParam.dictionary["MessageTitle"] = this.messageTitle;
    this.sendMsgParam.dictionary["MessageText"] = this.messageText;
    this.sendMsgParam.dictionary["ConfidentialText"] = this.confidentialText;

    await rest.url("notification/sendUserNotification")
      .post(this.sendMsgParam)
      .then(() => {
        this.syncedShowDialog = false;
      })
      .finally(() => {
        this.busy = false;
      })
  }

  // mailRules = [
  //   (v: string) => !!v || "Email is required",
  //   (v: string) =>
  //     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
  //       v
  //     ) || "Keine gültige E-Mail-Adresse"
  // ];

  titleRules = [
    (v: string) => !!v || "Titel erforderlich",
    (v: string) => (v && v.length > 3) || "Titel zu kurz",
    (v: string) => (v && v.length <= 200) || "Titel zu lang"
  ];

  shortTextRules = [
    (v: string) => (v.length <= 2048) || "Nachricht zu lang"
    // (v: string) => {
    //   console.log(`v2: '${v.length}'`);
    //   if (v && v.length >= 2048)
    //     return "Titel zu lang"
    //   return true;
    // }
  ];
}

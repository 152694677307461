import { render, staticRenderFns } from "./ContentManager.vue?vue&type=template&id=0f082ada&scoped=true&"
import script from "./ContentManager.vue?vue&type=script&lang=ts&"
export * from "./ContentManager.vue?vue&type=script&lang=ts&"
import style0 from "./ContentManager.vue?vue&type=style&index=0&id=0f082ada&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f082ada",
  null
  
)

export default component.exports

import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import rest from '@/rest';
import _ from "lodash";
import { List } from 'linq-collections';

// interfaces
import { TopicVm, ValueTupleOfStringAndString } from '@/interfaces/TopicVm';
import { ParamDictionary } from '@/interfaces/ParamDictionary';
import { ILanguage } from '@/interfaces/ILanguage';

@Component({
})
export default class DlgTopic extends Vue {
  @PropSync('showDialog') syncedShowDialog!: boolean;
  @Prop() editMode!: boolean;
  @Prop() topic!: TopicVm;

  busy = false;
  localTopic: TopicVm = {} as TopicVm;
  selectedLanguage = "de";
  selectedDescription: ValueTupleOfStringAndString | null = null;
  allLanguages: ILanguage[] = [{ id: "de", name: "Deutsch" }];

  mounted(): void {
  }

  @Watch('showDialog')
  async onShowDialogChanged(open) {
    if (open) {
      if (this.topic) {
        this.localTopic = _.cloneDeep(this.topic);
      } else {
        this.localTopic = {
          descriptions: [] as ValueTupleOfStringAndString[],
          createdDate: new Date(),
          createdBy: this.$store.state.auth.portalUser.fullName,
        } as TopicVm;
      }
      this.createDescriptions(this.localTopic);
      this.selectDescription(null);
    }
  }

  createDescriptions(topic: TopicVm) {
    this.allLanguages.forEach(l => {
      if (!topic.descriptions!.some(t => t.item1 === l.id)) {
        topic.descriptions!.push({item1: l.id, item2: ''});
      }
    });
  }

  selectDescription(language: ILanguage | null) {
    if (language) {
      this.selectedLanguage = language.id;
    }
    this.selectedDescription = new List(this.localTopic.descriptions!).singleOrDefault(d => d.item1 === this.selectedLanguage) ?? null;
  }

  async onSave() {
    if (this.busy == true)
      return;
    this.busy = true;

    this.localTopic.createdBy =  this.$store.state.auth.portalUser.fullName;
    this.localTopic.createdDate = new Date();
    rest.url('notification/saveTopic')
        .post(this.localTopic)
        .then(() => {
        })
        .finally(() => {
          this.busy = false;
          this.$emit('update:topic');
          this.syncedShowDialog = false;
        });
  }

  get languages() {
    if (!this.localTopic)
      return [];

    return new List(this.localTopic.descriptions!.filter(f => f.item1)).select(d => d.item1).toArray();
  }

  get dlgValid() {
    return true;
  }
}

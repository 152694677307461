
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { UserCredentials } from "@/interfaces/UserCredentials";
import rest from "@/rest";
import { vuex } from "@/store";

const auth = namespace("auth");

@Component({
  $_veeValidate: {
    validator: "new"
  }
})
export default class Register extends Vue {
  // @Prop({ default: false }) showDialog: boolean;
  // showDialog: boolean = false;
  @Prop() showDialog!: boolean;

  // @Watch("showDialog")
  // onShowDialogChanged(val: boolean) {
  // }

  password1: string = "";
  password2: string = "";
  userCredentials = {} as UserCredentials;
  showPassword: boolean = false;
  valid = true;

  $refs!: {
    registerform: HTMLFormElement;
  };

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length >= 3) || "Benutzername zu kurz."
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz."
  ];

  onVisibilityChanged(visible: boolean) {
    if (this.$refs.registerform != undefined) this.$refs.registerform.reset();

    this.$emit("visibilityChanged:register", visible);
  }

  @auth.Action PasswordLogin: any;
  @auth.Action RegisterUser: any;

  onCancel() {
    if (this.$refs.registerform != undefined) {
      this.$refs.registerform.reset();
    }
    this.$emit("visibilityChanged:register", false);
  }


  async doRegister() {
    if (!(await this.$validator.validate())) return;

    this.userCredentials.password = this.password1;

    await this.RegisterUser(this.userCredentials);
    if (this.$store.state.auth.isLoggedIn) {
      // this.$store.commit("ux/SB_SUCCESS", {
      //   message: "Registrirung erfolgreich   :-)",
      //   timeout: 3000
      // });
      vuex.ux.SB_SUCCESS({
        message: "Registrierung erfolgreich :)",
        timeout: 3000
      });
      this.onCancel();
    }
  }
}

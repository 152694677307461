
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List, Enumerable } from 'linq-collections';

// Import pdfmake-wrapper and the fonts to use
import { ICreatePDF, PdfMakeWrapper } from 'pdfmake-wrapper';
import * as pdfFonts from "pdfmake/build/vfs_fonts"; // fonts provided for pdfmake
import { Txt, IText } from 'pdfmake-wrapper';
import { Img, IImg } from 'pdfmake-wrapper';

// interfaces
import { UserCertificateVm } from "@/interfaces/UserCertificateVm";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { vuex } from "@/store";
import { User } from "@/store/types/auth";
import moment from "moment";
import { ParamDictionary } from "@/interfaces/ParamDictionary";

const auth = namespace("auth");
const globals = namespace("globals");

@Component({
  components: {
  }
})
export default class MyCertificatesView extends Vue {
  @auth.Getter isAdmin: any;
  @auth.Getter isOrgAdmin: any;
  @auth.Getter loggedInUser: any;

  // busy = false;
  certificates: UserCertificateVm[] = [];
  filteredCertificates: UserCertificateVm[] = [];
  search: string = "";

  // $refs!: {};

  mounted() {
    this.updateCertificateList();
    vuex.globals.setGroup(null);
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  @Watch("search")
  async onSearch(val) {
    this.filteredCertificates = new List(this.certificates).where(c => c.bundleName?.toLowerCase().includes(val.toLowerCase()) ?? false).toArray();
  }

  async updateCertificateList() {
    this.certificates = await rest.url("exercises/getUserCertificates").get();
    this.filteredCertificates = this.certificates;
  }

  certificateTitle(certificate: UserCertificateVm) {
    if (!certificate)
      return "";
    if (certificate.title)
      return certificate.title;
    return certificate.bundleName;
  }


  onLoadAllCertificatsPdf() {
    let params: ParamDictionary = { dictionary: {} };
    params.dictionary!["startDate"] = "";
    params.dictionary!["endDate"] = "";
    this.$globalHelper.blobDownloadWithAuth("api/exercises/loadUserCertificatesPdf", `${new Date().toISOString().substring(0, 10)}-${this.loggedInUser}-Zertifikate.pdf`, params);
  }

  async onLoadPdf(certificate: UserCertificateVm) {
    let pdf = await this.createPdfDocument(certificate);
    pdf.create().download(`Zertifikat - ${this.certificateTitle(certificate)}.pdf`);
  }

  async onPrintPdf(certificate: UserCertificateVm) {
    let pdf = await this.createPdfDocument(certificate);
    pdf.create().print();
  }

  async createPdfDocument(certificate: UserCertificateVm): Promise<PdfMakeWrapper> {
    // Docu: https://pdfmakewrapper.org/docs/getting-started/essential-concepts/main-classes; https://github.com/Lugriz/pdfmake-wrapper
    //       https://pdfmakewrapper.org/docs/api-references/document-definition
    //       https://github.com/bpampuch/pdfmake; https://pdfmake.github.io/docs/
    // vue to pdf: https://github.com/mizuka-wu/vue-pdfmake

    // Set the fonts to use
    PdfMakeWrapper.setFonts(pdfFonts);

    // Define document
    const pdf = new PdfMakeWrapper();
    pdf.pageSize('A4'); // A4 paper is 595.35 x 841.995 pt. Since A4 is 210 x 297 mm => 1 mm = 2.835 pt
    // pdf.pageMargins([ 40, 60, 40, 60 ]);
    pdf.info({
      title: 'Zertifikat',
      author: 'L3RN online',
      subject: this.certificateTitle(certificate) ?? "",
    });
    let def = pdf.getDefinition();
    // console.log("doc definitions:", def);

    // Set Background image
    const backgroundImage: IImg = await new Img('Zertifikat.png').fit([595.35, 841.995]).build();
    pdf.background(backgroundImage);

    // Winner image
    // const winner: IImg = await new Img('82-winner.png').fit([200, 300]).alignment("center").absolutePosition(180, 200).build();
    // pdf.add(winner);

    // User name
    // let text = new Txt([
    //   new Txt(`${certificate.firstName} ${certificate.lastName}\n\n`).bold().fontSize(24).color("#4089be").end,
    //   new Txt("hat die Schulung\n").fontSize(18).color("#494949").end,
    //   new Txt(`${certificate.bundleName}\n`).bold().fontSize(24).color("#4089be").end,
    //   new Txt(`am ${moment(certificate.closingDate).format("DD.MM.yyyy")}\ erfolgreich absolviert.`).fontSize(18).color("#494949").end,
    // ]).alignment("center").lineHeight(1.5).absolutePosition(180,350).end;

    let textArray = [
      new Txt(`${certificate.firstName} ${certificate.lastName}\n\n`).bold().fontSize(24).color("#4089be").end,
      new Txt("hat die Schulung\n").fontSize(18).color("#494949").end,
      new Txt(`${this.certificateTitle(certificate)}\n`).bold().fontSize(24).color("#4089be").end,
      new Txt(`am ${moment(certificate.closingDate).format("DD.MM.yyyy")}\ erfolgreich absolviert.`).fontSize(18).color("#494949").end,
    ];

    if (certificate.comment && certificate.comment.length > 0)
      textArray.push(new Txt(`\n\n${certificate.comment}`).fontSize(12).color("#494949").end);

    let text = new Txt(textArray).alignment("center").lineHeight(1.5).absolutePosition(180,350).end;
    pdf.add(text);

    if (certificate.signature && certificate.signature.length > 0) {
      const signature = (await new Img(certificate.signature).width(180).absolutePosition(180, 650).build()); //alignment("center").absolutePosition(180, 500);
      pdf.add(signature);
      let name = new Txt(`${certificate.firstName} ${certificate.lastName}`).fontSize(14).color("#494949").absolutePosition(210, 710).end;
      pdf.add(name);
    }

    // L3RN online
    // const l3rnOnline = new Txt([
    //   new Txt("L").color("#494949").end,
    //   new Txt("3").bold().color('#0C8AAD').end,
    //   new Txt("RN online").color("#494949").end,
    // ]).absolutePosition(480,785).end;
    // pdf.add(l3rnOnline);

    return pdf;
  }

  formatDate(date) {
    if (date == null)
      return "-";

    return moment(date).format("DD.MM.yyyy");
  }
}


  import _ from "lodash";
  import rest from "@/rest";
  import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
  import { Action, namespace } from "vuex-class";
  import { vuex } from "@/store";
  import { List } from "linq-collections";
  import TusFileDrop from "@/components/Shared/TusFileDrop.vue";
  import { signalrEventBus } from '@/main';

  // Interfaces
  import {
    ExBundleAssignmentGroupVM,
    ExBundleAssignmentVM,
  } from "../../interfaces/ExBundleAssignmentGroupVM";
  import {
    PortalUserVM,
    SelectListItem,
    SubGroupVm,
  } from "@/interfaces/PortalUserVM";
  import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
  import { GroupExtVm } from "@/interfaces/GroupExtVm";
import { ParamDictionary } from "@/interfaces/ParamDictionary";

  //const auth = namespace("auth");
  const globals = namespace("globals");

  @Component({
    components: {
      TusFileDrop
    },
  })
  export default class UserExcelUploadDlg extends Vue {
    @PropSync("showDialog") syncedShowDialog!: boolean;
    @Prop() assignments!: ExBundleAssignmentVM[];

    @globals.Getter selectedGroupAssignment!: ExBundleAssignmentVM;

    showFileProcessing = false;
    // busyOnImport = false;
    fileUploadBusy = false;
    bundleSearch = "";
    fileUploadList: string[] = [];
    fileInProcess = "";
    progress = 0;
    finishedSuccessfully = false;
    finishedWithError = false;
    successMessage = "";
    outputList: string[] = [];

    mounted() {}

    created() {
      // SignalR event listener
      signalrEventBus.$on('userFileImportUpdateProgress', this.updateImportProgress);
      signalrEventBus.$on('userFileImportFinished', this.importFinished);
    }

    beforeDestroy() {
      // clearAllBodyScrollLocks();
      // Make sure to cleanup SignalR event handlers when removing the component
      signalrEventBus.$off('userFileImportUpdateProgress', this.updateImportProgress);
      signalrEventBus.$off('userFileImportFinished', this.importFinished);
    }

    // SignalR events
    updateImportProgress(progress: number) {
      // console.log("updateImportProgress", progress);
      this.progress = progress;
    }

    importFinished(response: any) {
      // console.log("importFinished", errorMessage);
      if (response.status == "SUC") {
        this.finishedSuccessfully = true;
        this.successMessage = response.message;
      } else {
        this.finishedWithError = true;
        this.outputList.push(response.message);
      }

      this.$emit('imported:UserExcelUpload');
      this.fileUploadBusy = false;
      this.processFileUploadList();
    }

    @Watch("syncedShowDialog")
    async onShowDialogChanged(val: boolean) {
      if (this.syncedShowDialog) {
        this.showFileProcessing = false;
        this.fileUploadBusy = false;
        this.fileInProcess = "";
        this.outputList = [];

        // Disable body scroll on iOS
        this.$nextTick(async () => {
          await this.$globalHelper.delay(10);
          const modal = document.querySelector(".modal");
          disableBodyScroll(modal);
        });
      } else {
        // Dialog closing
        clearAllBodyScrollLocks();
      }
    }

    onFileUploadedToTempDir(response: any) {
      if (!response.success) {
        this.$store.commit("ux/SB_FAILURE", {
          message: response.message,
          timeout: 0
        });
        return;
      }

      // Add file to list and start import on server
      this.fileUploadList.push(response.fileName);
      this.processFileUploadList();
    }

    async processFileUploadList() {
      if (this.fileUploadList.length == 0 || this.fileUploadBusy)
        return;

      this.fileUploadBusy = true;
      this.fileInProcess = this.fileUploadList.pop()!;
      this.progress = 0;
      this.finishedWithError = false;
      this.finishedSuccessfully = false;
      this.successMessage = "";

      await this.$l3rnOnlineHub.ensureConnection();

      let params = <ParamDictionary>{dictionary: {}};
      params.dictionary!["SignalRId"] = this.$l3rnOnlineHub.ConnectionId!;
      params.dictionary!["FileName"] = this.fileInProcess;

      await rest.url("orgAdmin/processUserExcelFile")
      .post(params)
      .then(() => {
      })
      .catch(err => {
        this.fileUploadBusy = false;
        this.processFileUploadList();
      })
      .finally(() => {
      });

      // await rest
      // .url("orgAdmin/processUserExcelFile")
      // .query({ fileName: this.fileInProcess })
      // .get()
      // .then(async (response) => {
      //   if (!response) {
      //     return; // Ignore
      //   }
      //   if (response == "exists") {
      //     this.showFileProcessing = true;
      //   }
      //   if (response == "ok") {
      //     this.onImport(true);
      //   }
      // })
      // .catch(err => {
      //   this.fileUploadBusy = false;
      //   this.processFileUploadList();
      // })
      // .finally(() => {
      // });
    }

    // async onImport(overrideCerts: boolean) {
    //   this.showFileProcessing = false;
    //   // this.busyOnImport = true;
    //   await rest
    //   .url("groupAdmin/uploadCertificateFile")
    //   .query({ fileName: this.fileInProcess, overrideCerts: overrideCerts })
    //   .get()
    //   .then((response) => {
    //     if (response && response.length > 0)
    //     this.outputList = this.outputList.concat(response);
    //   })
    //   .finally(() => {
    //     this.fileUploadBusy = false;
    //     // this.busyOnImport = false;
    //     // this.$emit('fileLoaded:ExcelUpload');
    //     this.processFileUploadList();
    //   })
    // }

    onDeleteOutput() {
      this.outputList = [];
    }

    onCancel() {
      clearAllBodyScrollLocks();
      this.syncedShowDialog = false;
    }
  }
  
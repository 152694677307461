
import { Component, PropSync, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class About extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;

  showRightsHolderInfo: boolean = false;
}

import { FirebaseAppSettings, initializeApp } from 'firebase/app'
import { getMessaging, onMessage } from 'firebase/messaging'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyARrc4RkgPQ3r_0GjmMVP9XnpdtwDE1PhM",
  authDomain: "hessing-online.firebaseapp.com",
  projectId: "hessing-online",
  storageBucket: "hessing-online.appspot.com",
  messagingSenderId: "270421105372",
  appId: "1:270421105372:web:0322f9e6aaa0bfd9473219"
};

// Initialize Firebase
let app = initializeApp(firebaseConfig, <FirebaseAppSettings>{})

console.log('firebase app init')
// console.log(app)
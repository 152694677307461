
import _, { templateSettings } from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
    components: {}
})
export default class CompImage extends Vue {
  // @Prop() value!: string;
  @Prop() src!: string;
  @Prop() width!: string;
  @Prop() height!: string;
  @Prop() compSettings!: string;

  settings: any = null;
  showDialog = false;
  showCloseBtn = false;
  zoomerHeight = "500px";
  zoomerWidth = "500px";

  $refs!: {
    zoomer: any;
  };

  mounted() {
    if (this.compSettings) {
      this.settings = JSON.parse(this.compSettings);
    }

    this.setZoomerWindowSize();
  }

  async onOpenDlg() {
    if (!this.enableZoom)
      return;

    this.showDialog = true;
    await this.$globalHelper.delay(250);
    this.setZoomerWindowSize();
    this.showCloseBtn = true;
    this.$refs.zoomer.reset();
    // this.$refs.zoomer.zoomOut(0.5)
  }

  onCloseDlg() {
    this.showCloseBtn = false;
    this.$refs.zoomer.reset();
    this.showDialog = false;
  }

  get widthInPixel() {
    if (!this.width)
      return null;
    if (this.width.endsWith("px") || this.width.endsWith("%"))
        return this.width;

    return this.width + "px";
  }

  get heightValue() {
    if (!this.height)
      return null;
    if (this.height.endsWith("px"))
      return this.height.substring(0, this.height.length - 2);

    return this.height;
  }

  get enableZoom() {
    if (this.settings?.enableZoom)
      return this.settings.enableZoom;

    return false;
  }

  setZoomerWindowSize() {
    this.zoomerHeight = window.innerHeight + "px";
    this.zoomerWidth = window.innerWidth + "px";
  }
}

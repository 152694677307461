
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { vuex } from "@/store";
import { ICellRendererParams } from "ag-grid-community";
// import moment, { duration } from "moment";
import ExBundleStatus from "@/components/ExerciseComponents/ExBundleStatus.vue";

import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";

@Component({
  components: {
    ExBundleStatus,
  }
})
export default class ExOverviewStatusCellRenderer extends Vue {
  params!: ICellRendererParams;
  // $refs!: {
  // };

  mounted() {
    // if (!this.params || this.params.value == undefined) return;
    // this.checked = this.params.value;
  }

  get isGroupAdmin() {
    return vuex.auth.isGroupAdmin;
  }

  get assignment() {
    let rowData = (this.params?.data as ExBundleAssignmentVM);
    return rowData;
  }
}


import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { vuex } from "@/store";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import moment from "moment";

// interfaces

// components

const globals = namespace("globals");

@Component({
  components: {
  }
})
export default class L3DatePicker extends Vue {
  @PropSync("dateTime")
  syncedDateTime!: Date | null;
  @Prop()
  label!: string;
  @Prop({ default: false})
  isNullableDate!: boolean;
  @PropSync("isValid")
  syncedIsValid!: boolean;

  showDateDlg = false;
  dateAsIso: string | null = "";
  dateAsText: string | null = "";

  $refs!: {};

  mounted() {
    this.init();
  }

  // updated() {
  //   console.log("l3 date picker updated");
  //   this.update();
  // }

  // @Watch("syncedShowDialog")
  // async onShowDialogChanged(open: boolean) {
  //   if (open) {
  //     // On dialog open

  //     // Disable body scroll on iOS
  //     this.$nextTick(async () => {
  //       await this.$globalHelper.delay(10);
  //       const modal = document.querySelector('.modal');
  //       disableBodyScroll(modal);
  //     });
  //   } else {
  //     // Dialog closing
  //     clearAllBodyScrollLocks();
  //   }
  // }

  // beforeDestroy() {
  //   clearAllBodyScrollLocks();
  // }

  // closeDialog() {
  //   this.syncedShowDialog = false;
  // }


  @Watch("syncedDateTime")
  onDateChanged() {
    // console.log("onDateChanged");
    this.init();
  }

  init() {
    let isoDate = moment(this.syncedDateTime).format("yyyy-MM-DD");
    if (this.dateAsIso == isoDate)
      return;

    this.dateAsIso = isoDate;
    this.dateAsText = moment(this.dateAsIso).format("DD.MM.yyyy");
    // console.log("Update to: " + this.dateAsText);
    this.syncedIsValid = this.isValidDate(this.dateAsText);
  }

  onDatePicker() {
    if (!this.dateAsIso) {
      this.dateAsText = null;
      this.showDateDlg = false;
      return;
    }

    this.dateAsText = moment(this.dateAsIso).format("DD.MM.yyyy");
    this.setDate();
    this.showDateDlg = false;
  }

  get dateFormated() {
    return this.formatDate(this.dateAsIso);
  }

  setDate() {
    let time = moment(this.syncedDateTime).format("HH:mm"); // HH => 24h format
    this.syncedDateTime = moment(`${this.dateAsIso}T${time}`).toDate();
    this.$emit("dateChanged:DatePicker");
  }

  onDateTextChanged(): void {
    if (!this.dateAsText) {
      this.syncedIsValid = this.isNullableDate;
      if (this.isNullableDate) {
        this.dateAsIso = null;
        this.syncedDateTime = null;
        this.$emit("dateChanged:DatePicker");
      }

      return;
    }

    this.syncedIsValid = this.isValidDate(this.dateAsText);

    if (!this.syncedIsValid) {
      // this.dateAsIso = null; // moment(new Date()).format("yyyy-MM-DD");
      // this.dateAsText = null; // moment(new Date()).format("DD.MM.yyyy");
      return;
    }

    let isoDate = this.convertDeDateToIso(this.dateAsText)
    // Check if valid date
    if (!moment(isoDate).isValid()) {
      this.dateAsText = null;
      this.dateAsIso = null;
      this.syncedDateTime = null;
      this.$emit("dateChanged:DatePicker");
      this.syncedIsValid = false;
      return;
    }
    this.dateAsIso = isoDate;
    this.setDate();
  }

  isValidDate(val: string | null): boolean {
    // console.log("isValid: " + val)
    if (!val)
      return this.isNullableDate;

    let dateSplit = val.split('.');
    if (dateSplit?.length != 3)
      return false;
    if (dateSplit[2].length < 4)
      return false;
    if (dateSplit[1].length < 1 || dateSplit[1].length > 2)
      return false;
    if (dateSplit[0].length < 1 || dateSplit[0].length > 2)
      return false;

    return moment(this.convertDeDateToIso(val)).isValid();
  }

  convertDeDateToIso(val: string): string {
    // console.log("val", val);
    // console.log("this.dateAsText", this.dateAsText);
    // let dateSplit = this.dateAsText?.split('.');
    let dateSplit = val.split('.');
    let isoDate = dateSplit![2] + "-";
    if (dateSplit![1].length < 2)
      isoDate += "0";
    isoDate += dateSplit![1] + "-";
    if (dateSplit![0].length < 2)
      isoDate += "0";
    isoDate += dateSplit![0];
    return isoDate;
  }

  formatDate(date) {
    if (date == null)
      return "-";

    return moment(date).format("DD.MM.yyyy");
  }

  nullableDateRules = [
    (v: string) => this.isValidDate(v) || "falsches Datumsformat"
  ];

  dateRules = [
    (v: string) => !!v || "Datum erforderlich",
    // (v: string) => (v && this.isValidDate(v)) || "falsches Datumsformat"
    (v: string) => this.isValidDate(v) || "falsches Datumsformat"
  ];

}


  import _ from "lodash";
  import rest from "@/rest";
  import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
  import { Criteria, Condition, GroupExtVm } from "@/interfaces/GroupExtVm";
  // import { VRowExpandTransition } from "vuetify/lib"; // ???
  import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
  import { namespace } from "vuex-class";
  import { vuex } from "@/store";
  import { List } from "linq-collections";
  import vuetify from "@/plugins/vuetify";
  import { UserTagVm } from "@/interfaces/UserTagVm";
  import { CustomAttributeName } from "@/interfaces/CustomAttributeName";

  const globals = namespace("globals");

  class UserAttribute {
    text: string;
    value: string;

    constructor(text: string, value: string) {
      this.text = text;
      this.value = value;
    }
  }

  @Component({
    components: {}
  })
  export default class DynamicGroupOperators extends Vue {
    // @PropSync("showDialog") syncedShowDialog!: boolean;
    @Prop() criterias!: Criteria[];

    // @globals.Getter selectedOrg!: OrganizationVm;
    // @auth.Getter isAdmin: any;
    // @auth.Getter isOrgAdmin: any;
    // @auth.Getter isGroupAdmin: any;



    userAttributes: UserAttribute[] = [
      { text: "Jobbeschreibung", value: "Jobbeschreibung"},
      { text: "Organisation", value: "Organisation"},
      { text: "Standort", value: "Standort"},
      { text: "Abteilung", value: "Abteilung"},
      { text: "Kostenstelle", value: "Kostenstelle"},
      { text: "Eintrittsdatum", value: "Eintrittsdatum"},
      { text: "Tag", value: "Tag"},
    ];
    conditionTypes = [
      { text: "ist gleich", value: 0 }, { text: "ist ungleich", value: 4 },
      { text: "beginnt mit", value: 1 }, { text: "beginnt nicht mit", value: 5 },
      { text: "enthält", value: 2 }, { text: "enthält nicht", value: 6 },
      {text: "endet mit", value: 3 }, {text: "endet nicht mit", value: 7 }];
    entryDateTypes = [{ text: "1 Monat", value: 1 }, { text: "3 Monaten", value: 3 }, { text: "6 Monaten", value: 6 }, {text: "12 Monaten", value: 12 }, {text: "18 Monaten", value: 18 }];
    userTags: UserTagVm[] = [];
    tagTypes = [{ text: "ist", value: false }, { text: "ist nicht", value: true }];

    // $refs!: {
    // };

    async mounted() {
      // console.log("loadUserTags");
      this.loadUserTags();
      this.loadAndAddCustomAttributeNames();
    }

    // @Watch("showDialog")
    // async onShowDialogChanged(open: boolean) {
    //   if (open) {
    //     // On dialog open
    //     this.userTags = [];

    //     // Disable body scroll on iOS
    //     // this.$nextTick(async () => {
    //     //   await this.$globalHelper.delay(10);
    //     //   const modal = document.querySelector('.modal');
    //     //   disableBodyScroll(modal);
    //     // });
    //   } else {
    //     // Dialog closing
    //     // clearAllBodyScrollLocks();
    //   }
    // }

    async loadUserTags() {
      await rest.url("groupAdmin/loadUserTags")
        .get()
        .then((response) => {
          this.userTags = response;
        })
    }

    async loadAndAddCustomAttributeNames() {
    await rest.url("groupAdmin/loadCustomAttributeNames")
      .get()
      .then((response) => {
        let custAttributes = new List(response as CustomAttributeName[])
          .select(a => <UserAttribute>{ text: a.customName, value: a.attributeName})
          .toArray();
        this.userAttributes.push(...custAttributes);
        this.userAttributes = new List(this.userAttributes)
          .orderBy(a => a.text)
          .toArray();
      })
    }

    get addCriteriaLable() {
      return this.$vuetify.breakpoint.xs ? "Zusätz. Bedingungen" : "Bedingungen für zusätzliche Anwender";
    }

    onAddCriteria() {
      this.criterias.push(<Criteria>{ conditions: <Condition[]>[ <Condition>{ attributeName: "", conditionType: 0, compareValue: '' } ] });
    }

    onDeleteCriteria(orIndex: number) {
      new List(this.criterias).removeAt(orIndex);
    }

    onAddCondition(conditions: Condition[]) {
      conditions.push(<Condition>{ attributeName: "", conditionType: 0, compareValue: '' });
    }

    onDeleteCondition(conditions: Condition[], index: number) {
      new List(conditions).removeAt(index);
    }

    beforeDestroy() {
      // clearAllBodyScrollLocks();
    }

    // closeDialog() {
    //   this.syncedShowDialog = false;
    // }
  }
  

import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { vuex } from "@/store";
import rest from "@/rest";
import { List, Enumerable } from "linq-collections";
import {
  GridOptions,
  IDatasource,
  IGetRowsParams,
  Grid,
  ColDef,
  GridApi,
  IHeaderGroupParams,
  ValueGetterParams,
  ValueFormatterParams,
  CellValueChangedEvent,
  RowNode, CellDoubleClickedEvent, CellClickedEvent, Column
} from "ag-grid-community";
import 'ag-grid-community/styles/ag-grid.css';
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
import moment from "moment";

// Components
import AvatarCellRenderer from "@/components/CommonCellRenderers/AvatarCellRenderer.vue";
import GroupMemberContentCellRenderer from "@/components/Groups/GroupMemberContentCellRenderer.vue";
import GroupMemberActionCellRenderer from "@/components/Groups/GroupMemberActionCellRenderer.vue";
import SendMessage from "@/components/SendMessage.vue";

// Interfaces
import { UpdateUserResultsTableVm } from "@/interfaces/UpdateUserResultsTableVm";
import { UserResultsFilterSelectionsVm } from "@/interfaces/UserResultsFilterSelectionsVm";
import { ExBundleAssignmentVM, UserAssignmentsTableDataVm } from "@/interfaces/UserAssignmentsTableDataVm";
import router from "@/router";
import { LoadPartialMemberTableVm, MemberTableFilterVm } from "@/interfaces/LoadPartialMemberTableVm";
import { GroupVm, UserTableDataVm, PortalUserVM } from "@/interfaces/UserTableDataVm";
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    AgGridVue,
    SendMessage
  }
})
export default class GroupMemberTable extends Vue {
  @Prop() searchFilter!: MemberTableFilterVm;
  @PropSync("rowCount") syncedRowCount!: number;
  @PropSync("totalCount") syncedTotalCount!: number;

  // $refs!: {
  //   fcEntryGrid: HTMLElement;
  // };
  @globals.Getter selectedGroup!: GroupVm;
  @auth.Getter isAdmin: any;
  @auth.Getter isOrgAdmin: any;

  showMessageDialog = false;
  showDeleteDialog = false;
  selectedUser: PortalUserVM = new PortalUserVmClass();

  updateTableVm: UpdateUserResultsTableVm | null = null;
  agGridOptions = this.getGridOptions();
  agGridColumnDefs: ColDef[] = [];
  agGridFrameworkCmpts = {
    AvatarCellRenderer: AvatarCellRenderer,
    GroupMemberContentCellRenderer: GroupMemberContentCellRenderer,
    GroupMemberActionCellRenderer: GroupMemberActionCellRenderer
  };
  agGridApi = <GridApi>{};
  selectedRowData: ExBundleAssignmentVM | null = null;
  selectedRowNode: RowNode | null = null;
  selectedRowColumn: Column | null = null;
  selectedColName: string | undefined = "";
  selectedColHeader: string | undefined = "";

  mounted() {
    // this.refresh();
  }

  getGridOptions() {
    return <GridOptions>{
      context: this,
      // datasource: ,
      // data model options
      rowModelType: "infinite",
      rowBuffer: 0,
      cacheBlockSize: 100,
      cacheOverflowSize: 5,
      maxConcurrentDatasourceRequests: 1,
      infiniteInitialRowCount: 42,
      maxBlocksInCache: 200,
      // ux
      rowSelection: "multiple",
      sortingOrder: ["asc", "desc"],
      headerHeight: 0,
      rowHeight: 55,
      suppressContextMenu: true,
      preventDefaultOnContextMenu: true,
      stopEditingWhenCellsLoseFocus: true,
      // enableCellTextSelection: true, // Not working with cell renderers
      // components: {
      //   avatarCellRenderer: AvatarCellRenderer,
      // },
      // cbs
      onGridReady: async params => {
        // await this.$globalHelper.delay(10);
        this.agGridApi = params.api;
        params.api.onSortChanged = () => null;
        window.addEventListener('resize', function() {
          setTimeout(function() {
            params.api.sizeColumnsToFit();
          })
        })
      },
      // onFirstDataRedndered: event => {
      //   (event as FirstDataRenderedEvent).api.sizeColumnsToFit();
      // }
    };
  }

  getColumnDefs() {
    let colDef = new List();

    colDef.pushRange([
      {
        headerName: "",
        cellRenderer: "AvatarCellRenderer",
        // columnGroupShow: "open",
        // headerClass: "agTextBold",
        field: "avatar",
        width: 60,
        suppressSizeToFit: true,
        resizable: false
      },
      {
        headerName: "Name",
        // columnGroupShow: "open",
        cellRenderer: "GroupMemberContentCellRenderer",
        // headerClass: "agTextBold",
        field: "fullName",
        // width: 90,
        suppressSizeToFit: false,
        sortable: true,
        resizable: false
      },
      {
        headerName: "Aktion",
        cellRenderer: "GroupMemberActionCellRenderer",
        // columnGroupShow: "open",
        // headerClass: "agTextBold",
        field: "",
        width: 140,
        suppressSizeToFit: true,
        resizable: false
      },
    ]);

    return colDef.toArray() as any[];
  }

  dateFormatter(value: Date) {
    // let user = params.data as PortalUserVM;
    // if (!user || !user.gdprApprovalDate) return "-";
    // console.log("date", value);
    if (!value) return "-";

    let dateAsString = moment(value).format("DD.MM.yyyy");
    return dateAsString;
  }

  async loadData(): Promise<void> {
    // console.log("loadData");
    if (!this.agGridApi)
      return;
    this.agGridApi.ensureIndexVisible(0);
    this.agGridApi.setDatasource(new GroupMemberAgGridDataSource());
  }

  async updateTable(): Promise<void> {
    // console.log("Update data");

    // Workaround: On changing CollumnDefs some columns are mistakenly added to the end
    // this.agGridApi.setDatasource(new NoDataDatasource());
    this.agGridColumnDefs = <any>[];
    await this.$globalHelper.delay(50);
    // -------------------

    this.agGridColumnDefs = this.getColumnDefs();
    await this.$globalHelper.delay(50);
    this.agGridApi.ensureIndexVisible(0);
    this.agGridApi.setDatasource(new GroupMemberAgGridDataSource());
  }

  onUserPerformanceOverview(user: PortalUserVM) {
    if (user == null)
      return;

    // this.$store.commit('globals/setUser', user);
    vuex.globals.setUser(user);
    router.push("/userPerformanceOverview");
  }

  onNewMessage(user: PortalUserVM) {
    if (user == null)
      return;

    this.selectedUser = user;
    this.showMessageDialog = true;
  }

  // onEditUser(user: PortalUserVM) {
  //   this.isNewUser = false;
  //   this.selectedUser = _.cloneDeep(user);
  //   // this.selectedUser.groupId =  this.selectedGroup.id; // Will be done in EditUser component
  //   this.showEditUserDialog = true;
  // }

  onShowDeletePupilConfirmationDlg(user: PortalUserVM) {
    if (user == null)
      return;

    user.groupId = this.selectedGroup.id;
    this.selectedUser = user;
    this.showDeleteDialog = true;
  }

  async onDeleteUser() {
    if (this.selectedUser == null)
      return;

    await rest.url("groupAdmin/deleteUserFromGroup").post(this.selectedUser);
    // const index = this.users.indexOf(this.selectedUser);
    // this.users.splice(index, 1);
    this.updateTable();
    this.showDeleteDialog = false;
  }

  refresh(): void {
    this.agGridApi.refreshCells();
  }

  resetColumns(): void {
    this.agGridOptions.columnApi!.resetColumnState();
  }

  adjustColWidth(): void {
    this.agGridApi.sizeColumnsToFit();
  }

  // Not working for rowModelType: "infinite"
  // async loadData() {
  //   // console.log("Load data");
  //   let result = await rest().post("TargetingApi/LoadAmTableData", this.filterSelections);
  //   this.tableData = result.data;
  // }

  // Not working for rowModelType: "infinite"
  // async clear(): Promise<void> {
  // //   this.tableData = [];
  // }

  // ag-grid value formatter
  // https://www.ag-grid.com/javascript-grid-value-formatters/
  // formatCellValue(value: number, rowName: string) {
  //   let userLang = navigator.language;
  //   // console.log("The language is: " + userLang);
  //   // console.log(rowName);

  //   if (rowName == "User")
  //     return this.toPercent(value);
  //   return value.toLocaleString(userLang, { maximumFractionDigits: 0 });
  // }

  // toPercent(value: number) {
  //   return (value * 100).toFixed(1) + "%";
  // }

  // toCurrency(value: number): string {
  //   let userLang = navigator.language;
  //   return new Intl.NumberFormat(userLang, { style: 'currency', currency: 'EUR' }).format(value);
  // }

  /**
   * Events
   */

  onCellValueChanged(params: CellValueChangedEvent) {
    // console.log("onCellValueChanged");
    // console.log(params);

    // this.updateFcGmInPercent();
    // if (params.colDef.field == "Q1.CurrentValue") {
    //   params.data.Q1.ForecastDeviation = params.data.Q1.LastValue - params.data.Q1.CurrentValue;
    //   console.log(params.data.Q1.ForecastDeviation);
    // }
  }

  onRightClick() {
  }

  onCellClick(e: CellClickedEvent) {
    if (!e.data || !e.colDef)
      return;

    // console.log("onCellClick");
    // console.log(e.column);
    // console.log(e.colDef);
    // console.log(e);

    // this.selectedRowData = e.data as PortalUserVM;
    // console.log(this.selectedRowData);
    this.onUserPerformanceOverview(e.data as PortalUserVM);

    // this.selectedRowNode = e.node;
    // this.selectedRowColumn = e.column;
    // this.selectedColName = e.colDef.field?.split(".")[0] ?? "";
    // this.selectedColHeader = e.colDef.headerName;
    return;
  }

  // async onUpdateSelectedCell(newValue: number) {
  //   console.log("Update selected cell: " + newValue);
  //   if (!this.selectedRowNode || !this.selectedRowColumn)
  //     return;

  //   this.selectedRowNode.setDataValue(this.selectedRowColumn, newValue);
  //   // if (this.selectedRowData?.RowType == ForecastingValueType.Rev || this.selectedRowData?.RowType == ForecastingValueType.GM) {
  //   //   await this.loadData();
  //   // }
  // }

  /**
   * Getters
   */

  /**
   * Helper
   */
}

export class GroupMemberAgGridDataSource implements IDatasource {
  getRows = async (params: IGetRowsParams) => {
    let searchFilter: MemberTableFilterVm = params.context.searchFilter;
    // sort model
    let sortColumnName: string | null = null;
    let sortAsc = false;
    // check sort
    let sortAgGrid = params.sortModel[0] ?? null;
    if (sortAgGrid) {
      // console.log("sortAgGrid.colId", sortAgGrid.colId);
      sortColumnName = sortAgGrid.colId.split(".")[0];
      if (sortAgGrid.sort == "desc") sortAsc = false;
      if (sortAgGrid.sort == "asc") sortAsc = true;
    }

    params.context.updateTableVm = <LoadPartialMemberTableVm>{
      getRows: {
        startRow: params.startRow,
        endRow: params.endRow,
        columnName: sortColumnName,
        ascending: sortAsc
      },
      filter: searchFilter
    };

    await rest
      .url("groupAdmin/loadPartialMembers")
      .post(params.context.updateTableVm)
      .then(result => {
        // console.log(result)
        let tableData = result as UserTableDataVm;
        if (!tableData) return;
        params.context.syncedRowCount = tableData.hits;
        params.context.syncedTotalCount = tableData.total;
        params.successCallback(tableData.portalUsers as any[], tableData.hits);
        params.context.agGridApi.sizeColumnsToFit();
      });
  };
}

export class NoDataDatasource implements IDatasource {
  getRows = (params: IGetRowsParams) => {
    params.successCallback([]);
  };
}


import _, { templateSettings } from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed' // for VUE 2 (VUE3: import VuePdfEmbed from 'vue-pdf-embed')

const globals = namespace("globals");

@Component({
    components: {
      VuePdfEmbed
    }
})
export default class CompPdf extends Vue {
  // @Prop() value!: string;
  @Prop() src!: string;
  @Prop() width!: string;
  @Prop() height!: string;
  @Prop() compSettings!: string;

  settings: any = null;
  showDialog = false;
  showPdf = false;
  showCloseBtn = false;

  $refs!: {
    pdfViewer: any;
  };

  mounted() {
    if (this.compSettings) {
      this.settings = JSON.parse(this.compSettings);
    }
    let closed = this.settings?.closed ?? false;
    this.showPdf = !closed;
  }

  async onOpenDlg() {
    this.showDialog = true;
    await this.$globalHelper.delay(250);
    this.showCloseBtn = true;
  }

  onShow() {
    this.showPdf = !this.showPdf;
  }

  onCloseDlg() {
    this.showCloseBtn = false;
    this.showDialog = false;
  }

  onDownload() {
    this.$refs.pdfViewer.download();
  }

  onPrint() {
    this.$refs.pdfViewer.print();
  }

  get pdfTitle() {
    if (!this.src)
      return "";
    if (this.settings?.title)
      return this.settings.title;

    let pieces = this.src.split('/');
    let filename = this.src.split('/')[pieces.length-1];
    return filename.substring(0, filename.lastIndexOf('.'));;
  }

  get enablePrint() {
    if (this.settings?.enablePrint)
      return true;

    return false;
  }

  get enableDownload() {
    if (this.settings?.enableDownload)
      return true;

    return false;
  }

  get widthInPixel() {
    if (!this.width)
      return null;
    if (this.width.endsWith("px") || this.width.endsWith("%"))
        return this.width;

    return this.width + "px";
  }

  get heightValue() {
    if (!this.height)
      return null;
    if (this.height.endsWith("px"))
      return this.height.substring(0, this.height.length - 2);

    return this.height;
  }

  // get enableZoom() {
  //   if (this.settings?.enableZoom)
  //     return this.settings.enableZoom;

  //   return false;
  // }

  // setZoomerWindowSize() {
  //   this.zoomerHeight = window.innerHeight + "px";
  //   this.zoomerWidth = window.innerWidth + "px";
  // }
}

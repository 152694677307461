
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import moment from "moment";
// import { List } from "linq-collections";
// interfaces
import { CalendarEventVm } from "@/interfaces/CalendarEventVm";
// components
import L3DatePicker from "@/components/Shared/L3DatePicker.vue"
import L3TimePicker from "@/components/Shared/L3TimePicker.vue"

const auth = namespace("auth");
const globals = namespace("globals");

@Component({
  components: {
    L3DatePicker,
    L3TimePicker
  }
})
export default class EditEvent extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  @Prop() event!: CalendarEventVm | null;

  @auth.Getter isAdmin: any;
  @auth.Getter isGroupAdmin: any;
  @auth.Getter isOrgAdmin: any;

  colors = [
    {name: 'Indigo', color: 'indigo'},
    {name: 'Blau', color: 'blue'},
    {name: 'Cyan', color: 'cyan'},
    {name: 'Lila', color: 'deep-purple'},
    {name: 'Türkis', color: '#009688'},
    {name: 'Grün', color: 'green'},
    {name: 'Lime', color: 'lime darken-1'},
    {name: 'Orange', color: 'orange'},
    {name: 'Pink', color: 'pink lighten-1'},
    {name: 'Rot', color: 'red'},
    {name: 'Grau', color: 'grey darken-1'},
  ];
  busy = false;
  selectedEvent: CalendarEventVm | null = null;
  isValidStartDate = false;
  isValidEndDate = false;

  mounted() {}

  @Watch("syncedShowDialog")
  async onShowDialogChanged(open: boolean) {
    if (open) {
      // On dialog open
      if (!this.event) {
        let date = new Date();
        let startDate = this.roundToNextHalfHour(new Date());
        let endDate = new Date(startDate);
        endDate.setMinutes(endDate.getMinutes() + 30);

        this.selectedEvent = <CalendarEventVm>{
          id: 0,
          name: "",
          details: "",
          start: startDate,
          end: endDate,
          color: "grey",
          timed: true,
          lastChangeDate: new Date(),
          lastChangedBy: ""
        };
      } else {
        this.selectedEvent = null;
        await this.$globalHelper.delay(10);
        this.selectedEvent = _.clone(this.event);
      }

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector(".modal");
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closing
      clearAllBodyScrollLocks();
    }
  }

  get dialogTitle() {
    if (!this.event || this.event.id == 0) return "Neuer Termin";
    return "Termin bearbeiten";
  }

  get lastChangeDate() {
    let date = moment(this.selectedEvent?.lastChangeDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    console.log(date);
    return date;
  }

  get enableOkayBtn() {
    return (this.isValidStartDate && (this.selectedEvent?.name?.length ?? 0) > 2);
  }

  onStartDateTimeChanged() {
    // console.log("onStartDateTimeChanged");
    if (this.selectedEvent!.start < this.selectedEvent!.end)
      return;

    this.selectedEvent!.end = this.roundToNextHalfHour(this.selectedEvent!.start);
  }

  onEndDateTimeChanged() {
    // console.log("onEndDateTimeChanged");
    if (this.selectedEvent!.start < this.selectedEvent!.end)
      return;

    // console.log("start time: ", this.selectedEvent!.start);
    let newStartTime = new Date(this.selectedEvent!.end);
    newStartTime.setMinutes(newStartTime.getMinutes() - 30);
    // console.log("new start time: ", newStartTime);
    this.selectedEvent!.start = newStartTime;
  }

  roundToNextHalfHour(date: Date): Date {
    let roundedDate = new Date(date);
    if (date.getMinutes() >= 30) {
      roundedDate.setHours(date.getHours() + 1);
      roundedDate.setMinutes(0, 0, 0);
    } else {
      roundedDate.setMinutes(30, 0, 0);
    }
    return roundedDate;
  }

  async saveEvent() {
    this.busy = true;

    await rest.url("orgAdmin/creatOrUpdateCalendarEvent")
    .post(this.selectedEvent)
    .then((response) => {
      this.$emit("eventChanged:EditEvent");
      this.closeDialog();
    })
    .finally(() => {
      this.busy = false;
    });
  }

  async deleteEvent() {
    this.busy = true;

    await rest.url("orgAdmin/removeCalendarEvent")
    .post(this.selectedEvent)
    .then((response) => {
      this.$emit("eventChanged:EditEvent");
      this.closeDialog();
    })
    .finally(() => {
      this.busy = false;
    });
  }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  closeDialog() {
    this.syncedShowDialog = false;
  }

  titleRules = [
    (v: string) => !!v || "Titel erforderlich",
    (v: string) => (v && v.length > 2) || "Titel zu kurz",
    (v: string) => (v && v.length <= 50) || "Titel zu lang"
  ];
}


import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { QrMediaLinkVM } from "@/interfaces/QrMediaLinkVM";
import rest from "@/rest";
import router from "@/router";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  components: {
  }
})
export default class Freebies extends Vue {
  mediaLinkHash: string | null = null;
  mediaLink: QrMediaLinkVM | null = null;
  // params: ParamDictionary = { dictionary: {}};

  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.mediaLinkHash = urlParams.get("media");

    await this.loadmediaLinkHash();
  }

  async loadmediaLinkHash() {
    // this.params.dictionary!["Hash"] =  this.mediaLinkHash ?? "";
    this.mediaLink = await rest.url("resource/getMediaLink").query({ hash: this.mediaLinkHash }, true).get();
  }

  get youTubeLink() {
    if (!this.mediaLink?.youTubePath) return null;

    return "https://www.youtube-nocookie.com/embed/" + this.mediaLink?.youTubePath;
  }

  get isVideo() {
    if (!this.mediaLink?.resourcePath) return false;

    if (this.mediaLink.resourcePath.toLowerCase().endsWith(".mp4"))
      return true;

    return false;
  }

  get isAudio() {
    if (!this.mediaLink?.resourcePath) return false;

    if (this.mediaLink.resourcePath.toLowerCase().endsWith(".mp3"))
      return true;

    return false;
  }

  goHome() {
    if (router.currentRoute.path == "/")
      return;
    router.push("/");
  }
}


  import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";
  import { vuex } from "@/store";
  import rest from "@/rest";
  import UserTable from "@/components/UserManagement/UserTable.vue";
  import { PortalUserVmClass } from "@/classes/PortalUserVmClass";
  import { PortalUserVM } from "@/interfaces/PortalUserVM";
  import { GroupVm } from "@/interfaces/GroupVm";

  @Component({
    components: {
      // EditUser
    }
  })
  export default class DeleteUser extends Vue {
    @PropSync("showDialog") syncedShowDialog!: boolean;
    @Prop() user!: PortalUserVM;
    @Prop() deleteUserUrl!: string;
    userGroupList: GroupVm[] | null = null;
    busy = false;

    // $refs!: {
    // };

    mounted() {
    }

    @Watch("syncedShowDialog")
    async onShowDialog(value: boolean) {
      if (!value) return;

      // let user = this.params.data as PortalUserVM;
      this.userGroupList = null;
      this.userGroupList = await rest.url("orgAdmin/loadUserGroups").post(this.user);
    }

    async onDeleteClicked() {
      this.busy = true;

      await rest.url(this.deleteUserUrl)
      .post(this.user)
      .then((response) => {
        this.$emit('userDeleted:DeleteUser', true);
        this.syncedShowDialog = false;
      })
      .finally(() => {
        this.busy = false;
      });
    }

    get userName() {
      if (!this.user) return "-";
      return this.user.fullName;
    }

    /**
     * Helper
     */
  }


import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
    components: {}
})
export default class CompLinebreak extends Vue {
    @Prop() value!: string;

    // $refs!: {};

    mounted() {}
}


import _ from "lodash";
import rest from "@/rest";
import { Component, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import CompSelect from '@/components/ExerciseComponents/CompSelect.vue';
import CompSpeak from '@/components/ExerciseComponents/CompSpeak.vue';
import CompRight from '@/components/ExerciseComponents/CompRight.vue';
import CompWrong from '@/components/ExerciseComponents/CompWrong.vue';
import CompText from '@/components/ExerciseComponents/CompText.vue';
import CompImage from '@/components/ExerciseComponents/CompImage.vue';
import CompVideo from '@/components/ExerciseComponents/CompVideo.vue';
import CompPdf from '@/components/ExerciseComponents/CompPdf.vue';
import CompSignature from "@/components/ExerciseComponents/CompSignature.vue";

const ux = namespace("ux");

@Component({
  components: {
    CompSelect,
    CompSpeak,
    CompRight,
    CompWrong,
    CompText,
    CompImage,
    CompVideo,
    CompPdf,
    CompSignature
  }
})
export default class ContentCreationHelp extends Vue {
  @ux.State darkTheme: any;
  compRightFinalized = false;
  compWrongFinalized = false;

  mounted() {
    this.applyOrAdjustDigiClassTheme();
  }

  onRightClicked() {
    this.compRightFinalized = !this.compRightFinalized;
  }

  onWrongClicked() {
    this.compWrongFinalized = !this.compWrongFinalized;
  }

  applyOrAdjustDigiClassTheme() {
    // adjust old style
    let themeLink = document.getElementById('exerciseTheme');
    if (themeLink) {
      if (this.darkTheme)
        themeLink.setAttribute("href", "/styles/exercises-dark.css");
      else
        themeLink.setAttribute("href", "/styles/exercises-light.css");

      return;
    }

    // Create new one
    let styles = document.createElement('link');
    styles.type="text/css";
    styles.rel="stylesheet";
    styles.id="exerciseTheme";
    if (this.darkTheme)
      styles.href="/styles/exercises-dark.css";
    else
      styles.href="/styles/exercises-light.css";
    document.head.appendChild(styles);
  }

  async copyToClipboard(value: string) {
    await navigator.clipboard.writeText(value);
     this.$store.commit("ux/SB_SUCCESS", {
        message: value + " in Zwischenablage kopiert",
        timeout: 2000
      });
  }

  goBack() {
    router.go(-1);
  }
}

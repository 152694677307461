
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List, Enumerable } from 'linq-collections';
import EditUser from "@/components/UserManagement/EditUser.vue";
import AddUsers from "@/components/UserManagement/AddUsers.vue";
// interfaces
import { PortalUserVM, SelectListItem, GroupVm, OrganizationVm } from "@/interfaces/PortalUserVM";
// import { Org } from "@/interfaces/Org";
// import { PortalUser, Organization } from "@/interfaces/PortalUser";
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { vuex } from "@/store";
import { PortalUserVmClass } from "@/classes/PortalUserVmClass";
import { Organization } from "@/interfaces/UnsubscribedTopic";

const auth = namespace("auth");
const globals = namespace("globals");

@Component({
  components: {
    AddUsers,
    EditUser
  }
})
export default class OrganizationView extends Vue {
  @auth.Getter isAdmin: any;
  @auth.Getter isOrgAdmin: any;
  @globals.Getter selectedOrg!: OrganizationVm;

  busy = false;
  showNewOrgDialog = false;
  showEditOrgDialog = false;
  showDeleteOrgDialog = false;
  // selectedOrg: Org | null = null;
  localSelectedOrg: OrganizationVm | null = null;
  defaultOrg: OrganizationVm = {
    id: 0,
    name: "",
    street: "",
    zip: "",
    city: "",
    isOrgGroupAdmin: true
  };
  newOrg: OrganizationVm = _.cloneDeep(this.defaultOrg);
  editOrg: OrganizationVm =  _.cloneDeep(this.defaultOrg);
  newOrgFormValid: boolean = true;
  orgs: OrganizationVm[] = [];

  showNewGroupAdminDialog = false;
  showEditGroupAdminDialog = false;
  showDeleteGroupAdminDialog = false;
  selectedGroupAdminIdx: number = -1;
  defaultUser = new PortalUserVmClass();
  // isNewGroupAdmin = false;
  editGroupAdmin: PortalUserVM =  _.cloneDeep(this.defaultUser);
  deleteGroupAdmin: PortalUserVM =  _.cloneDeep(this.defaultUser);
  newGroupAdminFormValid: boolean = true;
  editGroupAdminFormValid: boolean = true;
  users: PortalUserVM[] = [];

  $refs!: {
    addNewOrgForm: HTMLFormElement;
    addNewGroupAdminForm: HTMLFormElement;
  };

  mounted() {
    this.updateOrgList(this.selectedOrg?.id ?? 0);
    vuex.globals.setGroup(null);
  }

  @Watch("showNewOrgDialog")
  onShowNewOrgDialog(val: boolean) {
    if (val == true) {
      // Dialog opened

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closed
      clearAllBodyScrollLocks();
    }
  }

  @Watch("showEditOrgDialog")
  onshowEditOrgDialog(val: boolean) {
    if (val == true) {
      // Dialog opened

      // Disable body scroll on iOS
      this.$nextTick(async () => {
        await this.$globalHelper.delay(10);
        const modal = document.querySelector('.modal');
        disableBodyScroll(modal);
      });
    } else {
      // Dialog closed
      clearAllBodyScrollLocks();
    }
  }

  // @Watch("showNewGroupAdminDialog")
  // onShowNewGroupAdminDialog(val: boolean) {
  //   if (val == true) {
  //     // Dialog opened

  //     // Disable body scroll on iOS
  //     this.$nextTick(async () => {
  //       await this.$globalHelper.delay(10);
  //       const modal = document.querySelector('.modal');
  //       disableBodyScroll(modal);
  //     });
  //   } else {
  //     // Dialog closed
  //     clearAllBodyScrollLocks();
  //   }
  // }

  // @Watch("showEditGroupAdminDialog")
  // onShowEditUserDialog(val: boolean) {
  //   if (val == true) {
  //     // Dialog opened

  //     // Disable body scroll on iOS
  //     this.$nextTick(async () => {
  //       await this.$globalHelper.delay(10);
  //       const modal = document.querySelector('.modal');
  //       disableBodyScroll(modal);
  //     });
  //   } else {
  //     // Dialog closed
  //     clearAllBodyScrollLocks();
  //   }
  // }

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  async updateOrgList(orgId: number) {
    this.orgs = await rest.url("orgAdmin/getOrganizations").get();
    // console.log("users:\r\n" + this.users);
    if (this.orgs.length > 0) {
      if (orgId > 0) {
        let org = new List(this.orgs).singleOrDefault(s => s.id == orgId) ?? this.orgs[0];
        vuex.globals.setOrg(org);
      } else {
        vuex.globals.setOrg(this.orgs[0]);
      }
    } else {
      vuex.globals.setOrg(null);
    }
    this.localSelectedOrg = this.selectedOrg;
    await this.loadGroupAdminList();
  }

  // --- Org Functions ---
  onEditOrg() {
    if (this.selectedOrg == null)
      return;

    this.editOrg = _.cloneDeep(this.selectedOrg);
    this.showEditOrgDialog = true;
  }

  onShowDeleteOrgDialog() {
    if (this.selectedOrg == null)
      return;
    this.showDeleteOrgDialog = true;
  }

  async onDeleteOrg() {
    if (this.selectedOrg == null)
      return;
    if (this.busy) return;
    this.busy = true;

    const index = this.orgs.indexOf(this.selectedOrg);
    await rest.url("orgadmin/deleteOrganization").post(this.selectedOrg)
    .finally(() => {
      this.busy = false;
    });
    await this.updateOrgList(this.selectedOrg?.id ?? 0);
    this.showDeleteOrgDialog = false;
  }

  closeEditOrgDialog() {
    this.showEditOrgDialog = false;
  }

  async saveEditOrgDialog() {
    if (this.busy) return;
    this.busy = true;
    await rest.url("orgadmin/saveOrganization").post(this.editOrg)
    .finally(() => {
      this.busy = false;
    });
    await this.updateOrgList(this.selectedOrg?.id ?? 0);
    this.closeEditOrgDialog();
  }

  openNewOrgDialog() {
    this.newOrg = _.cloneDeep(this.defaultOrg);
    this.showNewOrgDialog = true;
  }

  closeNewOrgDialog() {
    this.showNewOrgDialog = false;
  }

  async saveNewOrgDialog() {
    if (!this.$refs.addNewOrgForm.validate()) return;
    if (this.busy) return;
    this.busy = true;
    await rest.url("orgadmin/addOrganization")
      .post(this.newOrg)
      .then((response) => {
        console.log("new org id: ", response);
        this.updateOrgList(response);

        this.closeNewOrgDialog();
      })
      .finally(() => {
        this.busy = false;
      })
  }

  async onOrgChanged() {
    // console.log("onOrgChanged", this.localSelectedOrg?.name);
    vuex.globals.setOrg(this.localSelectedOrg);
    await this.loadGroupAdminList();
  }

  // --- Group Admin Functions ---
  async loadGroupAdminList() {
    if (this.selectedOrg == null) {
      this.users = [];
       return;
    }

    this.users = await rest.url("orgAdmin/getGroupAdmins").post(this.selectedOrg);
  }

  onEditUser(groupAdmin: PortalUserVM) {
    // this.selectedGroupAdminIdx = this.users.indexOf(groupAdmin);
    // this.editGroupAdmin = Object.assign({}, groupAdmin);
    // this.isNewGroupAdmin = false;
    this.editGroupAdmin = groupAdmin;
    this.showEditGroupAdminDialog = true;
  }

  async onCanEditDynGroupsChanged(groupAdmin: PortalUserVM) {
    this.busy = true;

    await rest.url("orgAdmin/createOrUpdateUser")
    .post(groupAdmin)
    .then((response) => {
    })
    .finally(() => {
      this.busy = false;
    });

  }

  onShowDeleteGroupAdminDialog(groupAdmin: PortalUserVM){
    this.deleteGroupAdmin = groupAdmin;
    this.deleteGroupAdmin.organizationId = this.selectedOrg!.id;
    this.showDeleteGroupAdminDialog = true;
  }

  async onDeleteGroupAdmin() {
    if (this.busy) return;
    this.busy = true;

    await rest.url("orgAdmin/removeGroupAdminFromOrg").post(this.deleteGroupAdmin)
    .finally(() => {
        this.busy = false;
      })

    // const index = this.users.indexOf(this.deleteGroupAdmin);
    // this.users.splice(index, 1);
    this.showDeleteGroupAdminDialog = false;
    this.loadGroupAdminList();
  }

  async onGroupAdminSaved() {
    this.loadGroupAdminList();
  }

  // async saveEditUserDialog() {
  //   if (this.selectedGroupAdminIdx > -1) {
  //     Object.assign(this.users[this.selectedGroupAdminIdx], this.editGroupAdmin);
  //     this.updateFullName(this.users[this.selectedGroupAdminIdx]);
  //     await rest.url("orgAdmin/saveGroupAdmin").post(this.editGroupAdmin);
  //   }

  //   this.closeEditUserDialog();
  // }

  // updateFullName(user: PortalUserVM) {
  //     if (user.lastName != null) {
  //       user.fullName = user.lastName;
  //     }
  //     if (user.lastName != null && user.firstName != null) {
  //       user.fullName += ", ";
  //     }
  //     if (user.firstName != null) {
  //       user.fullName += user.firstName;
  //     }
  // }

  openNewGroupAdminDialog() {
    // this.isNewGroupAdmin = true;
    this.editGroupAdmin = new PortalUserVmClass();
    // this.createNewKey();
    this.showNewGroupAdminDialog = true;
  }

  // createNewKey() {
  //   this.editGroupAdmin.activationKey = "";
  //   for (let i = 0; i <= 7; i++) {
  //     this.editGroupAdmin.activationKey += Math.floor(Math.random() * 10);
  //   }
  // }

  // Validation

  mailRules = [
    (v: string) => !!v || "Email is required",
    (v: string) =>
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) || "Keine gültige E-Mail-Adresse"
  ];

  mailEditRules = [
    (v: string) =>
      !v ||
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v
      ) ||
      "Keine gültige E-Mail-Adresse"
  ];

  usernameRules = [
    (v: string) => !!v || "Benutzername erforderlich",
    (v: string) => (v && v.length >= 3) || "Benutzername zu kurz"
  ];

  orgNameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 3) || "Name zu kurz"
  ];

  keyRules = [
    //(v: string) => !!v || "Schlüssel erforderlich",
    (v: string) => !!v || "Aktivierungsschlüssel erforderlich",
    (v: string) => (v && v.length > 7) || "Schlüssel zu kurz"
  ];

  keyEditRules = [
    (v: string) =>
      !v || (v && (v.length == 0 || v.length > 7)) || "Schlüssel zu kurz"
  ];

  nameRules = [
    (v: string) => !!v || "Name erforderlich",
    (v: string) => (v && v.length > 1) || "Name zu kurz"
  ];

  passwordRules = [
    (v: string) => !!v || "Passwort erforderlich",
    (v: string) => (v && v.length > 7) || "Passwort ist zu kurz"
  ];
}

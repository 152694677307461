import { ActionTree, GetterTree, MutationTree } from "vuex";
import rest, { refreshJwt } from "@/rest";
import router from "../../router";

import { Module } from "vuex";
import { RootStore } from "../types";
import { User } from "../types/auth";
import jwt_decode from "jwt-decode";
import { PortalUserVM } from "@/interfaces/PortalUserVM";
import { action, createModule, mutation } from "vuex-class-component";
import { vuex } from "@/store";
import { method } from "lodash";

const VuexModule = createModule({
  strict: false,
  namespaced: "auth",
});

export class Auth extends VuexModule {
  myName = "";
  loginTime = "";
  jwtExpiration = 0;
  isLoggedIn = false;
  portalUser: PortalUserVM | null = null;

  get loggedIn() {
    return this.isLoggedIn
  }
  get logoutTime() {
    return this.logoutTimeInSeconds;
  }
  get isAdmin() {
    // console.log("portalUser: ", this.portalUser);
    if (this.portalUser == null || this.portalUser.roles == null) return false;
    if (this.portalUser.roles.includes("Admin")) return true;
    return false;
  }
  get isNotificationAdmin() {
    if (this.portalUser == null || this.portalUser.roles == null) return false;
    if (this.portalUser.roles.includes("NotificationAdmin")) return true;
    return false;
  }
  get isContentManager() {
    if (this.portalUser == null || this.portalUser.roles == null) return false;
    if (this.portalUser.roles.includes("ContentManager")) return true;
    return false;
  }
  get isOrgAdmin() {
    if (this.portalUser == null || this.portalUser.roles == null) return false;
    if (this.portalUser.roles.includes("OrgAdmin")) return true;
    return false;
  }
  get isGroupAdmin() {
    if (this.portalUser == null || this.portalUser.roles == null) return false;
    if (this.portalUser.roles.includes("GroupAdmin")) return true;
    return false;
  }
  get canEditDynGroups() {
    if (this.portalUser == null) return false;
    return this.portalUser.canEditDynamicGroups;
  }
  get isGroupViewer() {
    if (this.portalUser == null || this.portalUser.roles == null) return false;
    if (this.portalUser.roles.includes("GroupViewer")) return true;
    return false;
  }
  get isLearner() {
    if (this.portalUser == null || this.portalUser.roles == null) return false;
    if (this.portalUser.roles.includes("Learner")) return true;
    return false;
  }

  get loginName() {
    if (this.portalUser == null) return "Bitte anmelden";
    if (this.portalUser.nickname != null && this.portalUser.nickname.length > 0)
      return this.portalUser.nickname;
    if (this.portalUser.fullName != null && this.portalUser.fullName.length > 0)
      return this.portalUser.fullName;
    if (this.portalUser.email != null && this.portalUser.email.length > 0)
      return this.portalUser.email;

    return this.portalUser.userName;
  }

  get loggedInUser() {
    if (!this.portalUser)
      return "";

    return `${this.portalUser.firstName} ${this.portalUser.lastName}`;
  }

  @action async RegisterUser(credentials) {
    let obtainJwt = await rest
      .url("Auth/register_portaluser")
      .post(credentials);
    if (!obtainJwt) return;

    window.localStorage.setItem(
      "digiClassAuth",
      // obtainJwt.token.split('"').join('')
      obtainJwt
    );
    await this.Login();
  }

  @action async PasswordLogin(credentials) {
    let obtainJwt = await rest.url("Auth/jwtSignIn").post(credentials);
    if (!obtainJwt) return;
    window.localStorage.setItem("digiClassAuth", obtainJwt);
    await this.Login();
  }

  @action async Login() {
    let loginJwt = refreshJwt();
    if (loginJwt == null) return;

    let portalUser: PortalUserVM = await rest.url("Auth/getPortalUser").get();
    if (portalUser == null) {
      window.localStorage.removeItem("digiClassAuth");
      this.Logout();
      return;
    }

    let payload = jwt_decode(loginJwt);
    this.setLogin({ payload, portalUser });
    this.setupIdleTimers();

    // start firebase service and register to topics
    // console.log("registerFcm");
    vuex.notify.registerFcm();

    // update notifications
    vuex.notify.loadTopicNotifications();
    vuex.notify.loadUserNotifications();

    // Set selectedUser if role is learner
    if (this.isLearner) vuex.globals.setUser(portalUser);

    //dispatch("mailAccount/obtainMailAccounts", null, { root: true })
  }

  @action async Logout() {
    window.localStorage.removeItem("digiClassAuth");
    this.setLogout();
    this.stopPollTimer();
    this.goHome();
  }

  @mutation setLogin({ payload, portalUser }) {
    this.myName = payload.sub;
    this.jwtExpiration = payload.exp;
    this.loginTime = new Date().toLocaleString("de-DE");
    this.isLoggedIn = true;
    // console.log("setLogin = ", portalUser);
    this.portalUser = portalUser;
    // vuex.ux.setReady();
  }

  @mutation setLogout() {
    this.myName = "";
    this.loginTime = "";
    this.jwtExpiration = 0;
    this.isLoggedIn = false;
    this.portalUser = null;
  }

  @mutation setPortalUser(portalUser) {
    this.portalUser = portalUser;
  }

  @action async goHome() {
    if (router.currentRoute.path == "/")
      return;
    router.push("/");
  }

  // auto logout
  timeoutInMs = 10 * 60 * 1000; // 10 minutes -> 10 * 60 * 1000ms
  pollTimerId = 0;
  timeoutStart = new Date();
  logoutTimeInSeconds = this.timeoutInMs / 1000;

  @action async setupIdleTimers() {
    // console.log("Start idle timer...");
    document.addEventListener("keypress", this.resetLogoutTimer, false);
    document.addEventListener("mousemove", this.resetLogoutTimer, false);
    document.addEventListener("mousedown", this.resetLogoutTimer, false);
    document.addEventListener("touchmove", this.resetLogoutTimer, false);
    document.addEventListener("scroll", this.resetLogoutTimer, false);

    if (this.isAdmin || this.isContentManager || this.isNotificationAdmin)
      this.timeoutInMs = 90 * 60 * 1000;// 90 minutes -> 90 * 60 * 1000ms
    else
      this.timeoutInMs = 10 * 60 * 1000; // 10 minutes -> 10 * 60 * 1000ms

    this.startPollTimer();
  }

  @action async resetLogoutTimer() {
    this.timeoutStart = new Date();
  }

  @action async handleInactive() {
    this.Logout();
  }

  @action async startPollTimer() {
    if (this.pollTimerId != 0)
      clearTimeout(this.pollTimerId);

    this.pollTimerId = setInterval(() => {
      let time = (new Date().getTime() - this.timeoutStart.getTime());
      let seconds = Math.round((this.timeoutInMs - time) / 1000);
      if (seconds < 0)
      seconds = 0;
      this.logoutTimeInSeconds = seconds;
      if (this.logoutTimeInSeconds == 0) {
        this.handleInactive();
        this.stopPollTimer();
      }
    }, 1000);
  }

  @action async stopPollTimer() {
    if (this.pollTimerId == 0)
      return;

    clearTimeout(this.pollTimerId);
    this.pollTimerId = 0;
  }
}


  import _ from "lodash";
  import rest from "@/rest";
  import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
  import { Action, namespace } from "vuex-class";
  import { vuex } from "@/store";
  import { List } from "linq-collections";
  import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
  import { ExBundleVM } from "@/interfaces/ExBundleVM";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
import { ExScormSettingsVm } from "@/interfaces/ExerciseVM";

  //const auth = namespace("auth");
  const globals = namespace("globals");

  @Component({ })
  export default class EditScormDetailsDlg extends Vue {
    // @PropSync("showDialog") syncedShowDialog!: boolean;
    @Prop() selectedBundle!: ExBundleVM;
    @Prop() scormName!: string;

    showDialog = false;
    busy = false;
    loading = false
    scormSettings: ExScormSettingsVm | null = null;

    mounted() {}

    @Watch("showDialog")
    async onShowDialogChanged(val: boolean) {
      if (this.showDialog) {
        this.scormSettings = null;
        this.loading = true
        await this.loadScormSettings();
        await this.$globalHelper.delay(100);
        this.loading = false;

        // Disable body scroll on iOS
        this.$nextTick(async () => {
          await this.$globalHelper.delay(10);
          const modal = document.querySelector(".modal");
          disableBodyScroll(modal);
        });
      } else {
        // Dialog closing
        // clearAllBodyScrollLocks();
      }
    }

    // beforeDestroy() {
    //   clearAllBodyScrollLocks();
    // }

    async loadScormSettings() {
      let params: ParamDictionary = { dictionary: {} };
      params.dictionary!["BundleId"] = this.selectedBundle.id.toString();
      params.dictionary!["ExerciseName"] = this.scormName;
      this.scormSettings = await rest.url("contentManager/loadExerciseSettings").post(params);
    }

    async saveScormSettings() {
      this.busy = true
      await rest.url("contentManager/saveExScormSettings").post(this.scormSettings)
        .finally(() => {
          this.busy = false;
          this.onClose();
        }
        )
    }

    onClose() {
      clearAllBodyScrollLocks();
      this.showDialog = false;
    }

    get isValidMinScore() {
      console.log(this.scormSettings);

      // if (!this.scormSettings?.minScore)
      if (isNaN(Number(this.scormSettings?.minScore)))
        return false;

      let minScore = this.scormSettings?.minScore ?? 0;
      if (minScore < 0 || minScore > 100)
        return false;

      return true;
    }

    get isValid() {
      return this.isValidMinScore;
    }

    // Rules
    minScoreRules = [
      (v: number) => !!v || "Erforderlich",
      (v: number) => (v && v >= 0) || "Der Wert sollte nicht kleiner wie 0 sein",
      (v: number) => (v && v <= 100) || "Der Wert sollte nicht größer 100 sein",
    ];

  }

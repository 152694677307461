
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";
import { SurveyExerciseVm, SurveyResultVm } from "@/interfaces/SurveyDetailedVm";
import { ExSurveyVm } from "@/interfaces/ExSurveyVm";

@Component({
  components: {
  }
})
export default class SurveyExerciseOverview extends Vue {
  @Prop() surveyExercise!: SurveyExerciseVm;

  participation =  0;
  minParticipantCnt = 4;
  showParticipationInfo = false;
  showUsableInfo = false;

  async mounted() {
    await this.$globalHelper.delay(300);
    // console.log(this.totalCount);
    if (this.surveyExercise && this.surveyExercise.totalAssignmentsCount != 0)
      this.participation = Math.round(this.surveyExercise.finishedAssignmentsCount * 100 / this.surveyExercise.totalAssignmentsCount);
  }

  get statusColor() {
    if (this.surveyExercise.availableResultCount < this.minParticipantCnt)
      return this.$store.state.ux.colorError;

      return this.$store.state.ux.colorAccent;
  }
}


import { Component, Vue } from "vue-property-decorator";
import router from "@/router";

@Component({
  components: {},
})
export default class Imprint extends Vue {
  mounted() {
    this.$globalHelper.scrollToTop();
  }

  goBack() {
    router.go(-1);
  }
}

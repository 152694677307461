
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { ExChoice } from '../../interfaces/ExDataSet';

const globals = namespace("globals");

@Component({
    components: {
    }
})
export default class CompChoice extends Vue {
  @Prop() choice!: ExChoice;
  @Prop() disableVerifyButton!: boolean;
  @Prop() noSuccessAnimation!: boolean; // True for exercise type is Survey
  @Prop() finalized!: boolean;
  isClicked: boolean = false;
  showSuccessCheck: boolean = false;
  showFailedCheck: boolean = false;

  $refs!: {
    choiceButton: any;
  };

  mounted() {}

  @Watch("disableVerifyButton")
  async setFocusOnVerifyButton(val: boolean) {
    if (!val) {
      await this.$globalHelper.delay(10);
      this.$refs.choiceButton.$el.focus();
    }
  }

  @Watch("finalized")
  async onFinalized(val: boolean) {
    if (val == false) {
      this.isClicked = false;
      this.showSuccessCheck = false;
      this.showFailedCheck = false;
      return;
    }

    if (this.choice.isVerifyButton && !this.noSuccessAnimation) {
      if (this.choice.isRight)
        this.showSuccessCheck = true;
      else
        this.showFailedCheck = true;
    }

    // this.choice.clickDisabled = true;
    // if (this.choice.isRight) {
    //     this.choice.state = "T";
    // }
    // else {
    //   this.choice.state = "F";
    // }
  }

  get disabled() {
    if (this.choice.isVerifyButton && this.disableVerifyButton)
      return true;
    return false;
  }

  checkAnswer() {
    if (this.finalized)
      return;

    if (this.noSuccessAnimation){
      this.$emit('next:CompChoice');
      return;
    }

    this.isClicked = true;

    if (this.choice.isVerifyButton) {
      this.$emit('verify:CompChoice');
      return;
    }

    if (this.choice.isRight) {
      // this.choice.state = "T";
      this.showSuccessCheck = true;
      this.$emit('succeded:CompChoice', true, false);
    } else {
      // this.choice.state = "F";
      this.showFailedCheck = true;
      this.$emit('succeded:CompChoice', false, false);
    }
  }
}

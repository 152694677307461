
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import router from "@/router";
import { PortalUserVM, SelectListItem, GroupVm, SubGroupVm } from "@/interfaces/PortalUserVM";
// import { ParamGetUserResultsForBundle } from "@/interfaces/ParamGetUserResultsForBundle";
// componennts
import ExBundleStatus from "@/components/ExerciseComponents/ExBundleStatus.vue";
import FinishByAdminDlg from "@/components/GroupExerciseOverview/FinishByAdminDlg.vue";
import ExOverviewTable from "@/components/GroupExerciseOverview/ExOverviewTable.vue";
// interfaces
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
import { vuex } from "@/store";
import { UserResultsFilterSelectionsVm } from "@/interfaces/UpdateUserResultsTableVm";
import { ParamDictionary } from "@/interfaces/ParamDictionary";
// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    ExOverviewTable,
    ExBundleStatus,
    FinishByAdminDlg
  }
})
export default class GroupExerciseOverview extends Vue {
  // @globals.Getter selectedGroup!: GroupVm;
  // @globals.Getter selectedSubGroup!: SubGroupVm;
  @globals.Getter selectedGroupAssignment!: ExBundleAssignmentVM;
  @globals.Getter groupExerciseOverviewSortByAlphabet!: boolean;
  @globals.Getter groupExerciseOverviewSortOrderAscending!: boolean;
  @auth.Getter isAdmin: any;
  @auth.Getter isGroupAdmin: any;

  showFinishByAdminDlg = false;
  // bundleAssignments: ExBundleAssignmentVM[] = [];
  filters = <UserResultsFilterSelectionsVm>{ ascending: true, bundleId: 0, groupId: 0, subGroupId: 0, search: null, inProgress: false, totalCount: 0, rowCount: 0};
  totalCount = 0;
  rowCount = 0;

  breadcrumbItems = [
    { text: 'Start', disabled: false, href: '/home' },
    { text: 'Schulungen', disabled: true, href: '/exercises' },
  ];

  $refs!: {
    assignmentsTable: ExOverviewTable;
  };

  async mounted() {
    if (this.selectedGroupAssignment == null) {
      router.push("/");
      return;
    }

    this.filters.groupId = this.selectedGroupAssignment.groupId;
    this.filters.subGroupId = this.selectedGroupAssignment.subGroupId;
    this.filters.bundleId = this.selectedGroupAssignment.exBundle?.id ?? 0;
    await this.updateTable();
  }

  @Watch("filters.search")
  async onSearch(val) {
    // if (this.init) return;

    this.debounce();
  }

  debounce = _.debounce(this.updateTable, 700, { leading: false, trailing: true});

  onChangeSortOder() {
    this.filters.ascending = !this.filters.ascending;
    this.updateTable();
  }

  onChangeInProgress() {
    this.filters.inProgress = !this.filters.inProgress;
    this.updateTable();
  }

  onLoadParticipantsPdf() {
    let params: ParamDictionary = { dictionary: {} };
    params.dictionary!["GroupId"] = this.filters.groupId.toString();
    params.dictionary!["SubGroupId"] = this.filters.subGroupId.toString();
    params.dictionary!["BundleId"] =this.filters.bundleId.toString();
    params.dictionary!["InProgress"] = this.filters.inProgress.toString();
    params.dictionary!["Search"] = this.filters.search ?? "";
    this.$globalHelper.blobDownloadWithAuth("api/exercises/LoadParticipantsPdf", `${this.selectedGroupAssignment.exBundle?.name}.pdf`, params);
  }

  updateTable() {
    this.$refs.assignmentsTable.updateTable();
  }

  loadTableData() {
    this.$refs.assignmentsTable.loadData();
  }

  setColWidth() {
    this.$refs.assignmentsTable.adjustColWidth();
  }

  resetCols() {
    this.$refs.assignmentsTable.resetColumns();
  }

  onSearchChanged() {
    this.updateTable();
  }

  // async getAssignments() {
  //   if (!this.selectedGroupAssignment || !this.selectedGroupAssignment.exBundle)
  //     return;

  //   // let pupilId: string = null;
  //   // if (this.selectedPupil != null)
  //   //   pupilId = this.selectedPupil.id;

  //   let param: ParamGetUserResultsForBundle = {
  //     // groupId: vuex.globals.selectedGroup?.id ?? 0,
  //     // subGroupId: vuex.globals.selectedSubGroup?.id ?? 0,
  //     groupId: this.selectedGroupAssignment.groupId,
  //     subGroupId: this.selectedGroupAssignment.subGroupId,
  //     bundleId: this.selectedGroupAssignment.exBundle.id,
  //     sortByAlphabet: this.groupExerciseOverviewSortByAlphabet,
  //     sortOrderAscending: this.groupExerciseOverviewSortOrderAscending
  //   };

  //   this.bundleAssignments = await rest.url("groupAdmin/getUserResultsForBundle").post(param);
  // }

  // onSort() {
  //   if (this.groupExerciseOverviewSortByAlphabet && this.groupExerciseOverviewSortOrderAscending) {
  //     this.$store.commit("globals/setGroupExerciseOverviewSortOrderAscending", false);
  //   } else if (this.groupExerciseOverviewSortByAlphabet && !this.groupExerciseOverviewSortOrderAscending) {
  //     this.$store.commit("globals/setGroupExerciseOverviewSortByAlphabet", false);
  //     this.$store.commit("globals/setGroupExerciseOverviewSortOrderAscending", true);
  //   } else if (!this.groupExerciseOverviewSortByAlphabet && this.groupExerciseOverviewSortOrderAscending) {
  //     this.$store.commit("globals/setGroupExerciseOverviewSortOrderAscending", false);
  //   } else if (!this.groupExerciseOverviewSortByAlphabet && !this.groupExerciseOverviewSortOrderAscending) {
  //     this.$store.commit("globals/setGroupExerciseOverviewSortByAlphabet", true);
  //     this.$store.commit("globals/setGroupExerciseOverviewSortOrderAscending", true);
  //   }

  //   this.getAssignments();
  // }

  // async showExerciseBundle(bundleAssignmentVm: ExBundleAssignmentVM) {
  //   // vuex.globals.setUser(bundleAssignmentVm.user);
  //   vuex.globals.setExBundleAssignment(bundleAssignmentVm);

  //   router.push("/exerciseBundle");
  // }

  get gridStyle() {
    // if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)
    //   return `width: 100%; height: calc(100vh - 48px - 40px - 32px - 12px - 56px - 8px - 20px - 36px);`;
    // else
    //   return `width: 100%; height: calc(100vh - 48px - 40px - 12px - 56px - 8px - 20px - 36px);`;
    return `height: calc(100vh - 320px);`
  }

  goBack() {
    router.go(-1);
  }
}

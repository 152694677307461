import { render, staticRenderFns } from "./SurveyRatingResult.vue?vue&type=template&id=ebcfc8b0&scoped=true&"
import script from "./SurveyRatingResult.vue?vue&type=script&lang=ts&"
export * from "./SurveyRatingResult.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebcfc8b0",
  null
  
)

export default component.exports

// import _ from "lodash";
// import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { quizPlayerEventBus } from '@/main';

const globals = namespace("globals");

@Component({
  components: {}
})
export default class CompSurveyComment extends Vue {
  @Prop() text!: string;
  @Prop() textArray!: string[];
  @Prop() size!: string; // e.g. 24px
  @Prop() color!: string; // e.g. #00A1EF
  @Prop() weight!: string; // normal, bold, bolder, lighter, 100, 200 ... 900
  @Prop() fontStyle!: string; // normal, italic, oblique
  @Prop() finalized!: boolean;
  @Prop() compSettings!: string;

  settings: any = null;
  comment = "";

  // $refs!: {};

  mounted() {
    if (this.compSettings) {
      this.settings = JSON.parse(this.compSettings);
    }
  }

  onInput() {
    if (!this.settings)
      return;

    quizPlayerEventBus.$emit("changed:Comment", { questionId: this.settings.questionId, comment: this.comment });
  }

  get accentColor() {
    if (this.color)
      return this.color;
    return this.$store.state.ux.colorAccent;
  }

  get backgroundColor() {
    if (this.$vuetify.theme.dark) {
      return "#ffffff30";
    }

    return "#ffffff80";
  }

  get textSize() {
    if (this.size == null || isNaN(Number(this.size)))
      return 18;
    return this.size;
  }

  get maxLength() {
    if (this.settings && this.settings.maxlength && this.settings.maxlength < 10000)
      return this.settings.maxlength;
    return 10000;
  }
}


  import _ from "lodash";
  import rest from "@/rest";
  import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
  import { Action, namespace } from "vuex-class";
  import { vuex } from "@/store";
  import { List } from "linq-collections";
  import TusFileDrop from "@/components/Shared/TusFileDrop.vue";
  // Interfaces
  import {
    ExBundleAssignmentGroupVM,
    ExBundleAssignmentVM,
  } from "../../interfaces/ExBundleAssignmentGroupVM";
  import {
    PortalUserVM,
    SelectListItem,
    SubGroupVm,
  } from "@/interfaces/PortalUserVM";
  import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
  import { GroupExtVm } from "@/interfaces/GroupExtVm";

  //const auth = namespace("auth");
  const globals = namespace("globals");

  @Component({
    components: {
      TusFileDrop
    },
  })
  export default class CertificateExcelUploadDlg extends Vue {
    @PropSync("showDialog") syncedShowDialog!: boolean;
    @Prop() assignments!: ExBundleAssignmentVM[];

    @globals.Getter selectedGroupAssignment!: ExBundleAssignmentVM;

    // showDialog = false;
    showFileExists = false;
    busyOnImport = false;
    fileUploadBusy = false;
    bundleSearch = "";
    bundleNames: string[] = [];
    selectedBundleName = "";
    fileUploadList: string[] = [];
    processedFile: string = "";
    outputList: string[] = [];

    mounted() {}

    @Watch("syncedShowDialog")
    async onShowDialogChanged(val: boolean) {
      if (this.syncedShowDialog) {
        this.showFileExists = false;
        this.busyOnImport = false;
        this.fileUploadBusy = false;
        this.selectedBundleName = "";
        this.bundleNames = [];
        this.processedFile = "";
        this.outputList = [];
        await this.loadBundleNameSuggestions();

        // Disable body scroll on iOS
        this.$nextTick(async () => {
          await this.$globalHelper.delay(10);
          const modal = document.querySelector(".modal");
          disableBodyScroll(modal);
        });
      } else {
        // Dialog closing
        clearAllBodyScrollLocks();
      }
    }

    beforeDestroy() {
      clearAllBodyScrollLocks();
    }

    @Watch("bundleSearch")
    onBundleSearchChanged () {
      this.debounce();
    }
    debounce = _.debounce(this.loadBundleNameSuggestions, 300, { leading: true, trailing: true});

    async loadBundleNameSuggestions() {
      // if (!this.bundleSearch)
      //   return;
      console.log("this.bundleSearch", this.bundleSearch);

      await rest
        .url("groupAdmin/getBundleNameSuggestions")
        .query({ search: this.bundleSearch ?? "" })
        .get()
        .then((bundleNames) => {
          this.bundleNames = bundleNames;
        })
    }

    async getExcelTemplate() {
      await this.debounce.cancel();
      await this.debounce.flush();
      await rest
        .url("groupAdmin/getCertificateTemplate")
        .query({ bundleName: this.selectedBundleName })
        .get()
        .then((fileName) => {
          if(fileName)
            this.$globalHelper.download(`api/resource/Temp/${fileName}`, fileName);
        })
    }

    onFileUploadedToTempDir(response: any) {
      if (!response.success) {
        this.$store.commit("ux/SB_FAILURE", {
          message: response.message,
          timeout: 0
        });
        return;
      }

      // Add file to list and start import on server
      this.fileUploadList.push(response.fileName);
      this.processFileUploadList();
    }

    async processFileUploadList() {
      if (this.fileUploadList.length == 0 || this.fileUploadBusy)
        return;

      this.fileUploadBusy = true;
      this.processedFile = this.fileUploadList.pop()!;

      await rest
      .url("groupAdmin/checkCertificateFile")
      .query({ fileName: this.processedFile })
      .get()
      .then(async (response) => {
        if (!response) {
          return; // Ignore
        }
        if (response == "exists") {
          this.showFileExists = true;
        }
        if (response == "ok") {
          this.onImport(true);
        }
      })
      .catch(err => {
        this.fileUploadBusy = false;
        this.processFileUploadList();
      })
      .finally(() => {
      });
    }

    async onImport(overrideCerts: boolean) {
      this.showFileExists = false;
      this.busyOnImport = true;
      await rest
      .url("groupAdmin/uploadCertificateFile")
      .query({ fileName: this.processedFile, overrideCerts: overrideCerts })
      .get()
      .then((response) => {
        if (response && response.length > 0)
        this.outputList = this.outputList.concat(response);
      })
      .finally(() => {
        this.fileUploadBusy = false;
        this.busyOnImport = false;
        // this.$emit('fileLoaded:ExcelUpload');
        this.processFileUploadList();
      })
    }

    onDeleteOutput() {
      this.outputList = [];
    }

    async onDeleteFile() {
      this.showFileExists = false;
      await rest
      .url("groupAdmin/deleteCertificateFile")
      .query({ fileName: this.processedFile })
      .get()
      .then(async (response) => {
      })
      .catch(err => {
      })
      .finally(() => {
        this.fileUploadBusy = false;
        this.showFileExists = false;
        this.processFileUploadList();
      });
    }

    onCancel() {
      clearAllBodyScrollLocks();
      this.syncedShowDialog = false;
    }
  }
  
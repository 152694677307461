
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List, Enumerable } from 'linq-collections';
import moment, { duration } from "moment";

// interfaces
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const auth = namespace("auth");

@Component({
  components: {}
})
export default class ToolsView extends Vue {
  showTemperatureDialog = false;
  minTemp1 = "4";
  minTemp2 = "0";
  maxTemp1 = "4";
  maxTemp2 = "0";

  @auth.Getter isAdmin: any;
  @auth.Getter isOrgAdmin: any;
  @auth.Getter isGroupAdmin: any;
  // @auth.Getter isParent: any;
  // @auth.Getter isPupil: any;

  mounted() {}

  beforeDestroy() {
    clearAllBodyScrollLocks();
  }

  get currentTime() {
    return moment(new Date()).format("DD.MM.yyyy hh:mm");
  }

  get minTemperature() {
    let minTemp = this.minTemp1.replace(" ", "") + "." + this.minTemp2;
    let temp = Number(minTemp);
    return temp;
  }

  get increaseTemp() {
    let minTemp = this.minTemp1.replace(" ", "") + "." + this.minTemp2;
    let temp = Number(minTemp);
    return temp < 2;
  }

  get decreaseTemp() {
    let maxTemp = this.maxTemp1 + "." + this.maxTemp2;
    let temp = Number(maxTemp);
    return temp > 8;
  }
}


import { Vue, Component, Prop, Watch } from "vue-property-decorator";
// import { Action, namespace } from "vuex-class";
import { StringWrapper } from "@/interfaces/StringWrapper";
import rest from "@/rest";
// A simple vue-component for client-side image upload with resizing
// https://vuejsexamples.com/a-simple-vue-component-for-client-side-image-upload-with-resizing/
import ImageUploader from "vue-image-upload-resize";
Vue.use(ImageUploader);

@Component({
})
export default class SetAvatar extends Vue {
  @Prop() avatar!: string;
  @Prop() disabled!: string;

  localAvatar: string = "";

  // $refs!: { };

mounted() {
  this.localAvatar = this.avatar;
}

  @Watch("avatar")
  async onAvatarChanged(val: string) {
    this.localAvatar = val;
  }


  onImgLoaded(e: any) {
    let img = e.target.files || e.dataTransfer.files;
    if (!img.length)
      return;

    this.createImage(img[0]);
  }

  async createImage(file: any) {
    if (file.size > (6000 * 1024)) {
      this.$store.commit('ux/SB_FAILURE', {
        message: 'Datei zu groß! Maximale Größe 6 MB'
      });

      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = async e => {
      let base64image: string = (e as any).target.result;
      if (!base64image.startsWith('data:image')) {
        this.$store.commit('ux/SB_FAILURE', {
          message: 'Ungültiges Dateiformat'
        });

        return;
      }

      // shrink and rotate image for avatar
      let stringWrapper: StringWrapper = { value: base64image };
      this.localAvatar = await rest.url('auth/createAvatar').post(stringWrapper);
      this.$emit('avatarChanged:setAvatar', this.localAvatar);
    };
  }

  setImage(file: any) {
    if (file == undefined || file.dataUrl == undefined || !file.dataUrl.startsWith('data:image')) {
      this.$store.commit('ux/SB_FAILURE', {
        message: 'Ungültiges Dateiformat'
      });
      return;
    }

    this.localAvatar = file.dataUrl;
    this.$emit('avatarChanged:setAvatar', this.localAvatar);
  }

  removeImage() {
    this.localAvatar = '';
    this.$emit('avatarChanged:setAvatar', this.localAvatar);
  }

}

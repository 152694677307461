
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";

const globals = namespace("globals");

@Component({
    components: {}
})
export default class CompHtml extends Vue {
    @Prop() text!: string;
    @Prop() textArray!: string[];
    @Prop() size!: string; // 24, evtl.: x-small, small, medium, large, x-large
    @Prop() color!: string;
    @Prop() weight!: string; // lighter, normal, bold, bolder, 100,200,300,400,500,600,700,800,900
    @Prop() fontStyle!: string; // normal, italic, oblique
    @Prop() finalized!: boolean;

    // $refs!: {};

    mounted() {}

    // render (createElement) {
    //     return createElement("span", this.hyperText);
    // }

    get fontSize() {
        if (this.size == null)
            return "18px";
        if (isNaN(Number(this.size))) // Number(this.size) != NaN)
            return this.size;
        return this.size + "px";
    }

    get lineHeight() {
        let fs = this.fontSize.replace("px", "");
        if (isNaN(Number(fs)))
            return this.fontSize;

        return Number(fs) + 2 + "px";
    }

    get hyperText() {
        if (this.text != null)
            return this.text;
        if (this.textArray != null)
            return this.textArray.join('');

        return null;
    }
}

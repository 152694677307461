
import _ from "lodash";
import rest from "@/rest";
import { Component, Vue, Watch, Prop, PropSync } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { List } from "linq-collections";
import {
  ExBundleAssignmentGroupVM,
  ExBundleAssignmentVM,
} from "../../interfaces/ExBundleAssignmentGroupVM";
import {
  PortalUserVM,
  SelectListItem,
  SubGroupVm,
} from "@/interfaces/PortalUserVM";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { GroupExtVm } from "@/interfaces/GroupExtVm";
import { vuex } from "@/store";
import { ParamGetUserResultsForBundle } from "@/interfaces/ParamGetUserResultsForBundle";
import moment from "moment";

//const auth = namespace("auth");
const globals = namespace("globals");

@Component({
  components: {},
})
export default class FinishByAdminDlg extends Vue {
  @PropSync("showDialog") syncedShowDialog!: boolean;
  // @Prop() assignments!: ExBundleAssignmentVM[];

  @globals.Getter selectedGroupAssignment!: ExBundleAssignmentVM;

  assignments: ExBundleAssignmentVM[] = [];
  selectedAssignment: ExBundleAssignmentVM | null = null;
  alreadySelectedUserIds = <string[]>[];
  comment: string | null = null;
  showCommentMenu = false;
  commentChangeOption: string = "doNotChange";
  busyOnSave = false;
  windowInnerHeight = 0;

  $refs!: {
    l3itemcomboox: any;
  };

  async forceCommentUpdate() {
    if (!this.$refs.l3itemcomboox)
      return;

    this.$refs.l3itemcomboox.blur();
    await this.$globalHelper.delay(5);
    this.selectedAssignment = null;
  }

  mounted() {
    this.setWindowHeight();
  }

  created() {
    window.addEventListener("resize", this.setWindowHeight);
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.setWindowHeight);
    clearAllBodyScrollLocks();
  }

  setWindowHeight() {
    this.windowInnerHeight = window.innerHeight;
  }

  async openMenu(on) {
    this.assignments = [];
    this.selectedAssignment = null;
    this.alreadySelectedUserIds = [];
    this.comment = null;
    this.commentChangeOption = "doNotChange";
    await this.getAssignments();

    // Disable body scroll on iOS
    this.$nextTick(async () => {
      await this.$globalHelper.delay(10);
      const modal = document.querySelector(".modal");
      disableBodyScroll(modal);
    });
  }

  async getAssignments() {
    if (!this.selectedGroupAssignment || !this.selectedGroupAssignment.exBundle)
      return;

    let param: ParamGetUserResultsForBundle = {
      groupId: this.selectedGroupAssignment.groupId,
      subGroupId: this.selectedGroupAssignment.subGroupId,
      bundleId: this.selectedGroupAssignment.exBundle.id
      // sortByAlphabet: this.groupExerciseOverviewSortByAlphabet,
      // sortOrderAscending: this.groupExerciseOverviewSortOrderAscending
    };

    this.assignments = await rest.url("groupAdmin/loadAssignmentsForApproval").post(param);
    let assignmentList = new List(this.assignments);
    this.alreadySelectedUserIds = assignmentList.where(a => a.finishedByAdmin && a.userId != null).select(a => a.userId ?? "").toArray();

    if (assignmentList.any(a => a.comment != null && a.comment.length > 0)) {
      this.comment = assignmentList.first(a => a.comment != null && a.comment.length > 0).comment;
    }
  }

  get commentSuggestions() {
    if (!this.assignments)
      return [];
    let assignmentList = new List(this.assignments);
    return assignmentList.where(a => a.comment != null && a.comment.length > 0).select(a => a.comment ?? "").distinct().toArray();
  }

  updateComment(item) {
    console.log(item.comment);
  }

  onApprovalChanged(item: ExBundleAssignmentVM) {
    item.finished = item.finishedByAdmin;
  }

  async onItemSelected(item: ExBundleAssignmentVM) {
    if (this.selectedAssignment == item)
      return;

    await this.forceCommentUpdate();

    if (item)
      this.selectedAssignment = item;
    else
      this.selectedAssignment = null;
  }

  onSelectAll() {
    let list = new List(this.assignments).where(a => !a.isSigned && !(a.finished && !a.finishedByAdmin));
    if (list.any(a => !a.finishedByAdmin))
      list.forEach(a => {
        a.finished = true;
        a.finishedByAdmin = true;
      });
    else
      list.forEach(a => {
        a.finished = false;
        a.finishedByAdmin = false;
    });
  }

  async onSave() {
    this.showCommentMenu = false;
    await this.$globalHelper.delay(10);
    // console.log("save: " + this.comment);

    this.busyOnSave = true;
    switch (this.commentChangeOption) {
      case "withoutComment":
        new List(this.assignments)
          .where(a => a.finishedByAdmin && (a.comment == null || a.comment.length == 0))
          .forEach(a => a.comment = this.comment);
        break;
      case "onlyNew":
        new List(this.assignments)
          .where(a => a.finishedByAdmin && !this.alreadySelectedUserIds.includes(a.userId ?? ""))
          .forEach(a => a.comment = this.comment);
        break;
      case "forAll":
        new List(this.assignments)
          .where(a => a.finishedByAdmin)
          .forEach(a => a.comment = this.comment);
        break;

      default:
        break;
    }

    rest
      .url("groupAdmin/finishTestsByAdmin")
      .post(this.assignments)
      .then(() => {
        this.$emit("updateView:finishByAdminDlg");
        this.onCancel();
      })
      .finally(() => {
        this.busyOnSave = false;
      })
  }

  onCancel() {
    clearAllBodyScrollLocks();
    this.syncedShowDialog = false;
  }

  isNewItem(item: ExBundleAssignmentVM): boolean {
    let alreadySelected = new List(this.alreadySelectedUserIds).contains(item.userId!);
    if (alreadySelected)
      return false;

    // console.log("is new " + item.user?.fullName + " " + item.finishedByAdmin);
    return item.finishedByAdmin;
  }

  isApprovalRemoved(item: ExBundleAssignmentVM): boolean {
    let notSelected = !new List(this.alreadySelectedUserIds).contains(item.userId!);
    if (notSelected)
      return false;

      return !item.finishedByAdmin;
  }

  finishedDate(item: ExBundleAssignmentVM): string {
    if (!item?.finishedByAdminDate)
      return "";

    // let date = moment(item?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let date = moment(item?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return " | " + date;
  }

  isSelectedItem(item: ExBundleAssignmentVM): boolean {
    if (!this.selectedAssignment || !item)
      return false;
    if (item.id == this.selectedAssignment.id)
      return true;
    return false;
  }

  itemBackgroundColor(item: ExBundleAssignmentVM): string {
    if (this.isSelectedItem(item))
      return "#88888830";
    return "#FFFFFF00";
  }

  // get commentChangeType() {
  //   switch (this.commentChangeOption) {
  //     case "doNotChange":
  //       return "Keine Änderungen vornehmen";

  //     default:
  //       return "-";
  //   }
  // }

  get commentActive() {
    return this.commentChangeOption != "doNotChange";
  }

  get commentLable() {
    if (this.commentChangeOption == "doNotChange")
      return "Kommentar Vergabe";
    if (this.commentChangeOption == "onlyNew")
      return "Kommentar für neue";
    if (this.commentChangeOption == "withoutComment")
      return "Kommentar falls nicht vorhanden";
    if (this.commentChangeOption == "forAll")
      return "Kommentar für alle";

    return "Kommentar";
  }

  get listHeight() {
    if (this.$vuetify.breakpoint.xs)
      return this.windowInnerHeight - 268;
    else
      return this.windowInnerHeight * 0.9 - 190;
  }

  // get dialogHeight() {
  //   console.log(this.windowInnerHeight);
  //   if (this.$vuetify.breakpoint.xs)
  //     return this.windowInnerHeight;
  //   else
  //     return this.windowInnerHeight * 0.9;
  // }
}

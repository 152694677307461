
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { vuex } from "@/store";
import { ICellRendererParams } from "ag-grid-community";
// import moment, { duration } from "moment";

import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
import moment from "moment";

@Component({
  components: {
  }
})
export default class ExOverviewContentCellRenderer extends Vue {
  params!: ICellRendererParams;
  // $refs!: {
  // };

  mounted() {
    // if (!this.params || this.params.value == undefined) return;
    // this.checked = this.params.value;
  }

  // get isGroupAdmin() {
  //   return vuex.auth.isGroupAdmin;
  // }

  get assignment() {
    let rowData = (this.params?.data as ExBundleAssignmentVM);
    return rowData;
  }

  get finishedDate() {
    if (!this.assignment?.finishedByAdminDate)
      return "";

    // let date = moment(this.assignment?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let date = moment(this.assignment?.finishedByAdminDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    // console.log(date);
    return " | " + date;
  }

}
